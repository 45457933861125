import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { detectNumColor } from '../../utils/detectColor'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './ChartComponents.less'

const BarChartStrategicPerformance = (props) => {

    const { dataVal } = props
    const { align, suffix, barPercentage, labels, dataChart, backgroundColor, maxVal, name, num, backCol } = dataVal

    const data = {
        labels: labels.map(el => el.split(' ')),
        datasets: [{
            data: dataChart,
            backgroundColor: backgroundColor
        }]
    }

    const options = {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        scaleShowLabels: false,
        centerText: {
            display: true,
            text: 'dfs'
        }, legend: {
            display: false
        },
        scales: {
            yAxes: [{
                gridLines: {
                    display: false,
                },

                ticks: {
                    max: maxVal,
                    display: false,
                    beginAtZero: true,
                }
            }],
            xAxes: [{
                barPercentage: barPercentage,
                gridLines: {
                    display: false,
                }, ticks: {
                    fontFamily: 'Factoria'
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: align,
                color: 'black',
                formatter: function (value) {
                    return `${value}${suffix}`;
                },
                font: {
                    color: '#000000',
                    family: 'Factoria',
                    size: 14
                }
            }
        }
    }

    return (
        <div className='intangible_chart_wrapper'>
            <div>{name}</div>
            <div style={{ backgroundColor: detectNumColor(num) }}>{num}</div>
            <div>
                <Bar
                    data={data}
                    width={250}
                    height={250}
                    options={options}
                />
            </div>
        </div>
    )
}

export default BarChartStrategicPerformance