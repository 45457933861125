import React from 'react'
import LineChart from '../../../ChartComponents/LineChart'
import './BrandAssociation.less'

const BrandAssociation = ({ dataVal }) => {

    const getChartData = (items) => {
        let obj = { labels: [], aware: [], unaware: [] }
        items.forEach((item) => {
            obj.labels = [...obj.labels, item.name];
            obj.aware = [...obj.aware, item.columnValues[0]]
            obj.unaware = [...obj.unaware, item.columnValues[1]]
        })
        return obj
    }

    const data = getChartData(dataVal.items)

    return (
        <div className='tab_container' style={{ flexFlow: 'column', justifyContent: 'center' }}>
            <div className='sponsorship_awareness'>
                <div className='property_dot' style={{ backgroundColor: '#00a5de' }}></div>
                <div>Aware of sponsorship</div>
                <div className='property_dot' style={{ backgroundColor: '#4c4c4c' }}></div>
                <div>Unaware of sponsorship</div>
            </div>
            <LineChart dataVal={data} />
        </div>
    )
}

export default BrandAssociation