import { http } from 'services/http';
import { PROPERTY_MAP } from './types';

export const propertyMapSearchData = () => async (dispatch) => {
  try {
    const res = await http.get(`/properties/filter/list`);
    dispatch({
      type: PROPERTY_MAP,
      payload: res.data.data,
    });
  } catch {}
};
