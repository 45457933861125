import React, { useState, useEffect } from 'react'
import ReactFileReader from 'react-file-reader'
import { http } from 'services/http'
import { notification } from 'antd'
import { Icon, Radio, Tooltip } from 'antd'
import Moment from 'react-moment'

import './DataUpload.less'
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

const RadioGroup = Radio.Group;


export const DataUpload = ({ adminPermission }) => {

    const {
        property_information,
        tangible_performance,
        strategic_performance,
        property_performance,
        uploadDelete,
        sponsorship_selections } = adminPermission

    const checkBoxOptions = [
        property_information && 'Property information',
        tangible_performance && 'Tangible performance',
        strategic_performance && 'Strategic performance',
        property_performance && 'Property performance',
        sponsorship_selections && 'Sponsorship selection'].filter(removeFalse => removeFalse)

    const [radioVal, setRadioVal] = useState('')
    const [fileForUpload, setFileForUpload] = useState(null)
    const [previousList, setPreviousList] = useState([])
    const [latest, setLatest] = useState(0)
    const [percentage, setPercentage] = useState(0);
    const [item, setItem] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const allowedFormats = { 'Tangible performance': ['.csv'], 'Property information': ['.xlsx', '.xls', '.csv'], 'Strategic performance': ['.xlsx', '.xls', '.csv'], 'Sponsorship selection': ['.csv'] }

    useEffect(() => {
        previusUploads()
    }, [])

    const onFilterChange = e => {
        setRadioVal(e.target.value)
    }

    const previusUploads = async () => {
        let res = await http.get('/previous-uploads')
        setLatest(res.data.latest)
        setPreviousList(res.data.data.reverse())
    }

    const handleFiles = (file) => {
        setFileForUpload(file.fileList[0])
    }

    const getRouteUpload = (radioVal) => {
        switch (radioVal) {
            case 'Property information':
                return '/properties/upload'
            case 'Tangible performance':
                return '/tangibles/upload'
            case 'Strategic performance':
                return '/strategic-performances/upload'
            case 'Sponsorship selection':
                return '/sponsorship-selections/upload'
            default:
                setRadioVal('')
                setFileForUpload(null)
                setPercentage(0)
                return false
        }
    }
    async function uploadFile() {

        if (getRouteUpload(radioVal)) {
            const routeUrl = getRouteUpload(radioVal)

            let formD = new FormData()
            formD.append('upload_file', fileForUpload)

            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total)

                    if (percent === 100) {
                        setPercentage('Processing...')
                    } else {
                        setPercentage(percent + ' ' + '%')
                    }
                }
            }
            try {
                let res = await http.post(routeUrl, formD, options)
                if (res.data?.message) { notification.success({ message: res.data?.message }) }
            } catch (err) {
                if (!!err.response?.data?.errors?.upload_file[0]) {
                    notification.warning({ message: err.response?.data?.errors?.upload_file[0] })
                }
            } finally {
                previusUploads()
                setRadioVal('')
                setFileForUpload(null)
                setPercentage(0)
            }
        }
    }

    const deleteUpload = () => {
        http.delete(`/previous-uploads/${item.id}`).then(() => {
            closeDialog();
            previusUploads();
        }).catch((err) => {
            let message = err.response?.data?.message;
            notification.warning({ message: message });
            closeDialog();
        });
    }

    const setDeleteItem = (item) => {
        setItem(item);
        setShowDeleteDialog(true);
    }

    const closeDialog = () => {
        setShowDeleteDialog(false);
        setItem(null);
    }

    return (
        <div className='tab_layout'>
            <div className='new_upload'>
                <div className='new_upload_name'>New upload</div>
                <div className='select_category'>Select category</div>
                <div className='upload_checkbox'>
                    <RadioGroup
                        options={checkBoxOptions}
                        value={radioVal}
                        onChange={onFilterChange}
                    />
                </div>
                <div className='select_file'>Select file</div>
                <ReactFileReader
                    disabled={!radioVal}
                    fileTypes={allowedFormats[radioVal]}
                    base64={true}
                    multipleFiles={false}
                    handleFiles={handleFiles}
                >
                    <div className='upload_document_wrapper'>  <div className='upload_document'><Icon type="upload" style={{ marginRight: '10px' }} />Upload document</div><div>{fileForUpload?.name}</div></div>
                </ReactFileReader>
                <div className='upload_document_wrapper'> <div className={`confirm_upload ${!!percentage && 'confirm_upload_disabled'}`} onClick={uploadFile}>Confirm upload</div>{!!percentage && <div>{percentage}</div>}</div>
            </div>
            <div className='previous_uploads'>
                <div className='previous_uploads_name'>Previous uploads</div>
                <div className='upload_list'>
                    <div className='upload_list_item_description'>
                        <div>Property</div>
                        <div>Category</div>
                        <div>Date</div>
                        <div>Status</div>
                    </div>
                    {previousList.map(({ type, file_name, success, ended_at, id, errors }) => {
                        return <UploadListItem
                            key={id}
                            property={file_name}
                            category={type}
                            date={ended_at}
                            status={!!success ? 'check' : 'close'}
                            errors={errors}
                            setDeleteItem={setDeleteItem}
                            id={id}
                            uploadDelete={!success || id === latest ? uploadDelete : null}
                        />
                    })}
                </div>

            </div>
            {item &&
                <ConfirmDialog
                    closeDialog={closeDialog}
                    itemName={item.name}
                    showDialog={showDeleteDialog}
                    modalTitle="Delete data upload"
                    handleDelete={deleteUpload}
                />
            }
        </div>
    )
}

export default DataUpload

const UploadListItem = ({ property, category, date, status, errors, setDeleteItem, id, uploadDelete }) => {

    const listOfErrors = () => {
        if (!!errors) {
            return <ul>
                {errors.map((err, i) => <li key={i}>{err}</li>)}
            </ul>

        }
    }

    const errorHover = () => {
        return <Tooltip placement="left" title={listOfErrors}><Icon type={status} style={{ color: status === 'close' ? '#eb462b' : '#04ad63' }} /></Tooltip>
    }

    const noError = () => {
        return <Icon type={status} style={{ color: status === 'close' ? '#eb462b' : '#04ad63' }} />
    }


    return <div className='upload_list_item'>
        <div>{property}</div>
        <div>{category}</div>
        <div><Moment format="DD/MM/YYYY HH:mm">{date}</Moment></div>
        <div>
            <span className="icon-container">
                {!!errors ? errorHover() : noError()}
            </span>
            {uploadDelete &&
                <span className="icon-container">
                   <Icon type="delete" style={{ fontSize: '10px' }} onClick={() => setDeleteItem({ id, name: property })} />
                </span>
            }
        </div>
    </div >

}
