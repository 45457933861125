import React, { useState, useEffect } from 'react'
import { Form, Icon, Input, Tree, Spin, notification } from 'antd'
import { http } from 'services/http'
import Moment from 'react-moment'
import Pagination from "react-js-pagination";

import './UserManagement.less'
import log from "loglevel";

const { TreeNode } = Tree;


const UserManagement = ({ form, role }) => {
    const { getFieldDecorator, validateFields, resetFields, setFields, setFieldsValue } = form;
    const [userLevelTabs, setUserLevelTabs] = useState([{ name: 'Client', role: 'client' }, { name: 'Admin', role: 'admin' }, { name: 'Super Admin', role: 'super admin' }])
    const [activeTab, setActivetab] = useState('')
    const [permissions, setPermissions] = useState({})
    const [nameEmail, setNameEmail] = useState({})
    const [userloading, setUserLoading] = useState(false)
    const [listOfUsers, setListOfUsers] = useState([])
    const [metaOfUsers, setMetaOfUsers] = useState([])
    const [activeUser, setActiveUser] = useState(null)
    const { property_permissions, website_permissions, upload_permissions, user_permissions } = permissions

    //selected permissions
    const [property, setProperty] = useState([])
    const [website, setWebsite] = useState([])
    const [upload, setUpload] = useState([])
    const [user, setUser] = useState([])

    //extended
    const [expanded, setExpanded] = useState({ property: [], website: [], upload: [], user: [] })

    useEffect(() => {
        getPermissions()
        getListOfUsers()
        updateUserLevelTabs(role)
    }, [])


    const fetchUser = async (id) => {
        resetChecked();
        setUserLoading(true)
        try {
            let res = await http.get(`/users/${id}`)
            setActiveUser(id)
            setActivetab(res.data.data.role)
            updateChecked(res.data.data)
        } catch (err) {

        } finally {
            setUserLoading(false)
        }
    }

    const getListOfUsers = async (page = 1) => {
        let res = await http.get('/users?page=' + page)
        if (res.data) {
            setListOfUsers(res.data?.data.reverse())
            setMetaOfUsers(res.data?.meta)
        }
    }

    const resetChecked = () => {
        setProperty([])
        setWebsite([])
        setUpload([])
        setUser([])
        setActivetab('')
        resetFields()
    }

    const removeUnused = (data) => {
        let res = data.map(el => {
            return typeof el == 'number' ? el.toString() : el
        }).filter(el => !el.startsWith('unused:'))
        return res

    }

    const setChecked = (type, dataa) => {
        let data = removeUnused(dataa)
        switch (type) {
            case 'property':
                setProperty(data)
                break;
            case 'website':
                setWebsite(data)
                break;
            case 'upload':
                setUpload(data)
                break;
            case 'user':
                setUser(data);
                break;
            default:
        }
    }

    const updateUserLevelTabs = (role) => {

        switch (role) {
            case 'admin':
                setUserLevelTabs([{ name: 'Client', role: 'client' }])
                break;
            case 'super admin':
                setUserLevelTabs([{ name: 'Client', role: 'client' }, { name: 'Admin', role: 'admin' }, { name: 'Super Admin', role: 'super admin' }])
                break;
            case 'client':
                setUserLevelTabs(null)
                break;
            default:
                setUserLevelTabs(null)
        }
    }

    const updateChecked = (data) => {

        const { email, first_name } = data

        if (!!data['property_permissions'].length) {
            setChecked('property', data['property_permissions'])
        } else {
            setChecked('property', [])
        }

        if (!!data['website_permissions'].length) {
            setChecked('website', data['website_permissions'])
        } else {
            setChecked('website', [])
        }

        if (!!data['upload_permissions'].length) {
            setChecked('upload', data['upload_permissions'])
        } else {
            setChecked('upload', [])
        }

        if (!!data['user_permissions'].length) {
            setChecked('user', data['user_permissions'])
        } else {
            setChecked('user', [])
        }

        setFieldsValue({
            fullname: first_name,
            email
        })
        setNameEmail({
            fullname: first_name,
            email
        })

    }

    const changeActiveTab = (tab) => {
        setActivetab(tab)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFields((error, values) => {
            if (!error) {
                setNameEmail(values)
            }
        });
    };

    const getPermissions = async () => {
        let res = await http.get('/permissions')
        if (res.data?.data) {
            setPermissions(res.data.data)
        }

    }

    const renderTreeNodes = data =>
        data.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} />;
        });

    const onExpand = (expand, type) => {
        setExpanded({
            ...expanded,
            [type]: expand
        })

    }

    const onCheck = (checked, type) => {
        setChecked(type, checked)
    }

    const getDataBasedOnRole = (type) => {
        const { fullname, email, password } = nameEmail
        switch (type) {
            case 'client':
                return {
                    first_name: fullname,
                    email,
                    role: activeTab,
                    property_permissions: property,
                    website_permissions: website,
                    password: password,
                }

            case 'admin':
                return {
                    first_name: fullname,
                    email,
                    role: activeTab,
                    upload_permissions: upload,
                    user_permissions: user,
                    password: password,
                }
            case 'super admin':
                return {
                    first_name: fullname,
                    email,
                    role: activeTab,
                    password: password,
                }
            default:
                return false
        }
    }

    const createUser = async () => {
        setUserLoading(true)
        let data = getDataBasedOnRole(activeTab)
        try {
            await http.post('/users', data)
            getListOfUsers()
            notification.success({ message: 'The user has been created' })
        } catch (err) {
            if (err.response?.data?.message) {
                notification.warning({ message: err.response.data.message })
            }

        } finally {
            setUserLoading(false)
            resetChecked()
        }

    }

    const cancelActiveUser = () => {
        resetChecked()
        setActiveUser(null)
    }

    const updateUser = async () => {
        let data = getDataBasedOnRole(activeTab);
        if (data) {
            setUserLoading(true)
            data.id = activeUser;

            try {
                await http.patch(`/users/${data.id}`, data)
                notification.success({ message: 'The user has been updated!' })
            } catch (err) {
                let errors = err.response?.data?.errors ? Object.keys(err.response.data.errors) : '';
                let message = err.response?.data?.message;
                if (errors) {
                    errors.forEach(error => {
                        notification.warning({ message: err.response.data.errors[error] });
                    })
                } else if(message) {
                    notification.warning({ message: message });
                }
            } finally {
                cancelActiveUser()
                setUserLoading(false)
            }
        }
    }

    const deactivateUser = async () => {
        if (!!activeUser) {
            setUserLoading(true)
            try {
                let res = await http.delete(`/users/${activeUser}`)
                getListOfUsers()
            } catch (err) {
                console.log(err.response)
                log.error(err)
            } finally {
                cancelActiveUser()
                setUserLoading(false)
            }
        }
    }

    const setTree = (type) => {

        switch (type) {
            case 'client':
                return (
                    <>
                        <div className='user_level'>Properties permissions</div>
                        {!!property_permissions ?
                            <Tree
                                onExpand={e => onExpand(e, 'property')}
                                expandedKeys={expanded.property}
                                onCheck={checked => onCheck(checked, 'property')}
                                checkedKeys={property}
                                checkable>
                                {renderTreeNodes(property_permissions)}
                            </Tree> :
                            <div>loading</div>}
                        <div className='user_level'>Website permissions</div>
                        {!!website_permissions ?
                            <Tree
                                onExpand={e => onExpand(e, 'website')}
                                expandedKeys={expanded.website}
                                onCheck={checked => onCheck(checked, 'website')}
                                checkedKeys={website}
                                checkable>
                                {renderTreeNodes(website_permissions)}
                            </Tree> :
                            <div>loading</div>}
                    </>)
            case 'admin':
                return (
                    <>
                        <div className='user_level'> </div>
                        {!!upload_permissions ?
                            <Tree onExpand={e => onExpand(e, 'upload')}
                                expandedKeys={expanded.upload}
                                onCheck={checked => onCheck(checked, 'upload')}
                                checkedKeys={upload}
                                checkable>
                                {renderTreeNodes(upload_permissions)}
                            </Tree> :
                            <div>loading</div>}
                        {!!user_permissions ?
                            <Tree onExpand={e => onExpand(e, 'user')}
                                expandedKeys={expanded.user}
                                onCheck={checked => onCheck(checked, 'user')}
                                checkedKeys={user}
                                checkable>{renderTreeNodes(user_permissions)}
                            </Tree> :
                            <div>loading</div>}
                    </>)
            case 'super admin':
                return ""
            default:
                return ""

        }
    }

    return (!!userLevelTabs &&
        <div className='tab_layout'>
            <div className='all_users'>
                <div className='all_users_name'>All users</div>
                <div className='all_users_list'>
                    <div className='user_item_description'>
                        <div>Name</div>
                        <div>Email</div>
                        <div>Created on</div>
                    </div>
                    {!!listOfUsers.length && listOfUsers.map(el => {
                        const { first_name, email, updated_at, id } = el
                        return < UserItem key={id} name={first_name} email={email} created={updated_at} active={id === activeUser} click={() => fetchUser(id)} />
                    })}
                </div>
              {metaOfUsers.per_page < metaOfUsers.total && (
                <Pagination
                  activePage={metaOfUsers.current_page}
                  itemsCountPerPage={metaOfUsers.per_page}
                  totalItemsCount={metaOfUsers.total}
                  pageRangeDisplayed={5}
                  itemClass="page-item"
                  linkClass="page-link"
                  onChange={(page) => {
                    getListOfUsers(page);
                  }}
                />
              )}

            </div>
            <div className='edit_user'>
                {userloading && <div className='user_management_loader'>
                    <Spin size='large' tip="Loading..." />
                </div>}
                <div style={{ position: 'relative' }} className={userloading ? 'blur_element' : undefined}>

                    {!!activeUser && <Icon type="close" className='edit_user_close_button' onClick={cancelActiveUser} />}

                    <div className='new_user_name'>{activeUser ? 'Edit user' : 'New user'}</div>
                    <div className='user_details'>User details</div>
                    <div className='input_names'>
                        <div>Full name</div>
                        <div>Email</div>
                    </div>
                    <div className='admin_form'>
                        <Form onChange={handleSubmit} className='user_form'>
                            <div className='horizontal'>
                                <Form.Item>
                                    {getFieldDecorator('fullname')(
                                        <Input
                                            type="text"
                                            placeholder="Full name"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input your Email!"
                                            },
                                            {
                                                type: "email",
                                                message: "The input is not valid Email!"
                                            }
                                        ]
                                    })(<Input placeholder="example@example.com" autoComplete='off'/>)}
                                </Form.Item>
                            </div>

                            <div className='input_names'>
                                <div>Password</div>
                            </div>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: !activeUser,
                                            message: "The password is required!"
                                        },
                                        {
                                            pattern: new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,15}$"),
                                            message: "Required 8 to 15 letters, at least one number, one lowercase and uppercase letter and one special character!"
                                        }
                                    ]
                                })(<Input.Password placeholder="Password" autoComplete='off' />)}
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="user_level">User level</div>
                    <div className="user_level_tabs">
                        {userLevelTabs.map(el => <div key={el.role} className={activeTab === el.role ? 'admin_active_tab' : undefined} onClick={() => changeActiveTab(el.role)}>{el.name}</div>)}
                    </div>
                    {!!Object.keys(permissions).length && setTree(activeTab)}
                </div>
                {!activeUser ? <div className={`create_user ${userloading ? 'blur_element' : undefined}`} onClick={createUser}>Create user</div> :
                    <div className={`deactivate_update_user ${userloading ? 'blur_element' : undefined}`}>
                        <div onClick={deactivateUser}>Deactivate user</div>
                        <div onClick={updateUser}>Update user</div>
                    </div>}
            </div>
        </div >
    )
}

export default Form.create()(UserManagement)

const UserItem = ({ name, email, created, click, active }) => {
    return <div className={`user_item ${active ? 'active_user' : undefined}`} onClick={click}>
        <div>{name}</div>
        <div>{email}</div>
        <div><Moment format="DD.MM.YYYY HH:mm">{created}</Moment><Icon type={!active ? 'right' : 'edit'} style={{ fontSize: '10px' }} /></div>
    </div>
}
