export const detectNumColor = (num) => {
    if (num < 95) return '#e30303';
    if (num >= 95 && num < 105) return '#f39000';
    if (num >= 105 && num < 140) return '#2ea836';
    if (num >= 140) return '#007808';
}

export const detectTabColor = (num) => {
    if (num < 95) return 'ant-tabs-ink-bar-red';
    if (num >= 95 && num < 105) return 'ant-tabs-ink-bar-orange';
    if (num >= 105 && num < 140) return 'ant-tabs-ink-bar-green';
    if (num >= 140) return 'ant-tabs-ink-bar-super-green';
}

export const selectionColor = (num) => {
    if (num < 4) return '#e30303';
    if (num >= 4 && num < 6) return '#f39000';
    if (num >= 6 && num < 8) return '#04ad63';
    if (num >= 8) return '#026a3c';
}

export const selectionTabColor = (num) => {
    if (num < 4) return 'slection_tab_red';
    if (num >= 4 && num < 6) return 'slection_tab_orange';
    if (num >= 6 && num < 8) return 'slection_tab_green';
    if (num >= 8) return 'slection_tab_super_green';
}