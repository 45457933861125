import React, { useState, useEffect } from 'react';
import { Input, Icon } from 'antd';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { http } from 'services/http';
import { FilterSingleItem } from '../SponsorshipEvaluation';
import ReactTooltip from 'react-tooltip';
import MapChart from '../../ChartComponents/WorldMap';
import { Element, scroller } from 'react-scroll';
import { connect } from 'react-redux';
import qs from 'qs';
import { propertyMapSearchData } from '../../../redux/actions/propertyMap';
import {
  europe,
  north_america,
  asia_pacific,
  all_countries,
} from '../../ChartComponents/countries';

import './PropertyMap.less';

const { Search } = Input;

const PropertyMap = ({
  searchData,
  loading,
  propertyMapSearchData,
  location
}) => {
  let { url } = useRouteMatch();
  let history = useHistory();
  const filterData = [
    { name: 'Market', type: 'markets' },
    { name: 'Brand/Operator', type: 'brands' },
    { name: 'Sport', type: 'sports' },
    { name: 'Type', type: 'types' },
    { name: 'Property', type: 'properties' },
  ];
  const [content, setContent] = useState('');
  const [arrowDirection, setArrowDirection] = useState({
    regions: true,
    markets: false,
    brands: false,
    sports: false,
    type: false,
    properties: true,
  });
  const [searchQuery, setSearchQuery] = useState({
    brands: [],
    markets: [],
    properties: [],
    regions: '',
    sports: [],
    types: [],
  });
  const [searchInput, setSearchInput] = useState({
    brands: [],
    markets: [],
    properties: [],
    regions: [],
    sports: [],
    types: [],
  });
  const [propertiesData, setPropertiesData] = useState([]);

  async function fetchData() {
    const query = qs.parse(location.search.substr(1));
    if (!!Object.keys(query).length) {
      try {
        const res = await http.post('properties/filter/property-map', query);
        setPropertiesData(res.data.data);
      } catch {
        setPropertiesData([]);
      }
    }
  }

  useEffect(() => {
    propertyMapSearchData();
    setSearchQuery({ ...searchQuery, ...qs.parse(location.search.substr(1)) });
    // setSearchQuery({ ...qs.parse(location.search.substr(1)) })
  }, []);

  useEffect(() => {
    setSearchInput({ ...searchInput, ...searchData })
  }, [searchData])

  useEffect(() => {
    fetchData();
  }, [searchQuery]);

  const setArrow = (propType) => {
    setArrowDirection({
      ...arrowDirection,
      [propType]: !arrowDirection[propType],
    });
  };

  const setQueryParam = (item, propType) => {
    const newQuery = {
      ...searchQuery,
      [propType]: [...new Set([...searchQuery[propType], item])],
    };
    setSearchQuery(newQuery);
    history.push(`${url}?${qs.stringify(newQuery)}`);
  };

  const setRegionQuery = (region) => {
    const newQuery = { ...searchQuery, regions: region };
    setSearchQuery(newQuery);
    history.push(`${url}?${qs.stringify(newQuery)}`);
  };

  const removeQueryParam = (item, propType) => {
    const selectedQuery = new Set(searchQuery[propType]);
    selectedQuery.delete(item);
    const newQuery = { ...searchQuery, [propType]: [...selectedQuery] };
    setSearchQuery(newQuery);
    history.push(`${url}?${qs.stringify(newQuery)}`);
  };

  const searchDataQuery = (str, propType) => {
    let res;
    if (str.length > 0) {
      if (propType == 'markets') {
        res = searchData.markets[searchQuery.regions].filter((item) =>
          item.toLowerCase().includes(str.toLowerCase()),
        );
      } else {
        res = searchData[propType].filter((item) =>
          item.toLowerCase().includes(str.toLowerCase()),
        );
      }
      setSearchInput({ ...searchInput, [propType]: res });
    } else {
      console.log(searchQuery, propType)
      setSearchInput({ ...searchInput, [propType]: searchData[propType] });
    }
  };

  const resetAllFilters = () => {
    setSearchQuery({
      brands: [],
      markets: [],
      properties: [],
      regions: '',
      sports: [],
      types: [],
    });
    history.push(`${url}`);
  };

  const getRegion = () => {
    const region = qs.parse(location.search.substr(1)).regions;
    switch (region) {
      case 'Europe':
        return europe;
      case 'Global':
        return all_countries;
      case 'Asia Pacific':
        return asia_pacific;
      case 'North America':
        return north_america;
      default:
        return new Set([]);
    }
  };
  const viewPropertiesScropt = () => {
    scroller.scrollTo('properties', {
      duration: 500,
      delay: 10,
      smooth: true,
      offset: -10,
    });
  }

  const calcNumAndRosiAverage = () => {
    return {
      lengthOfProp: propertiesData.length,
      average: !!propertiesData.length ? propertiesData.reduce(((acc, curr) => acc + curr.latest_rosi_score), 0) / propertiesData.length : 0
    }
  }
  return (
    !loading && (
      <div className="sponsorship_evaulation_layout">
        <div className="sponsorship_evaulation_filter">
          <div className="property_map" style={{ margin: '40px' }}>
            <div className="property_map_filters_name">Filters</div>

            {/* ============ Region ============ */}
            <FilterSingleItem
              name="Region"
              direction={arrowDirection.regions}
              click={() => setArrow('regions')}
            />
            {arrowDirection.regions && (
              <div className="sponsorship_evaulation_filter_results">
                {['Global', 'Asia Pacific', 'Europe', 'North America'].map(
                  (item) => (
                    <div
                      key={item}
                      className={`filter_item ${
                        searchQuery.regions &&
                        searchQuery.regions === item &&
                        'conditional_render'
                        }`}
                      onClick={() => setRegionQuery(item)}>
                      {item}
                    </div>
                  ),
                )}
              </div>
            )}

            {/*============ Other Filters ============*/}
            {filterData.map(({ name, type }) => {
              return (
                <div key={name}>
                  <FilterSingleItem
                    key={name}
                    name={name}
                    direction={arrowDirection[type]}
                    click={() => setArrow(type)}
                  />
                  {arrowDirection[type] && (
                    <div>
                      {searchQuery[type] && !!searchQuery[type].length && (
                        <div className="sponsorship_evaulation_filter_results">
                          {searchQuery[type].map((item) => (
                            <div
                              key={item}
                              className="filter_item filter_item_selected"
                              onClick={() => removeQueryParam(item, type)}>
                              {item}
                              <Icon
                                type="close"
                                style={{ fontSize: '11px', marginLeft: '12px' }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="filter_search_input_field">
                        <Search
                          placeholder="Start typing"
                          onChange={(e) =>
                            searchDataQuery(e.target.value, type)
                          }
                          disabled={
                            type == 'markets' &&
                              !searchData.regions.includes(searchQuery.regions)
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="sponsorship_evaulation_filter_results search_results">
                        {searchInput[type] &&
                          (type === 'markets' ?
                            searchInput[type][searchQuery.regions]?.map((item) => {
                              return (
                                <div
                                  key={item}
                                  className="filter_item"
                                  onClick={() => setQueryParam(item, [type])}>
                                  {item}
                                </div>
                              );
                            }) :
                            searchInput[type].map((item) => {
                              return (
                                <div
                                  key={item}
                                  className="filter_item"
                                  onClick={() => setQueryParam(item, [type])}>
                                  {item}
                                </div>
                              );
                            }))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div className="reset_all_filters" onClick={resetAllFilters}>
              Reset all filters
            </div>
          </div>
        </div>
        <div className="sponsorship_evaulation_result">
          <div className="sponsorship_evaulation_map">
            <div className="evaulation_property_map_name">Property Map</div>

            <div className="evaulation_map_tabs">
              {['Global', 'Europe', 'North America', 'Asia Pacific'].map(
                (item) => (
                  <div
                    key={item}
                    className={`evaulation_map_single_tab ${
                      searchQuery.regions &&
                      searchQuery.regions === item &&
                      'evaulation_map_single_tab_active'
                      }`}
                    onClick={() => setRegionQuery(item)}>
                    {item}
                  </div>
                ),
              )}
            </div>

            <div
              className="evaulation_world_map"
              style={{ position: 'relative', pointerEvents: 'none' }}>
              <MapChart
                countries={getRegion()}
                setTooltipContent={setContent}
              />
              <ReactTooltip>{content}</ReactTooltip>
              {!!propertiesData.length && <div className='world_map_data_box'>
                <div>Number of properties:{'  '}{calcNumAndRosiAverage().lengthOfProp}</div>
                <div>Average ROSI score:{'  '}{Math.round(calcNumAndRosiAverage().average)}</div>
                <div onClick={viewPropertiesScropt}>View properties</div>
              </div>}
            </div>
          </div>
          <div className="evaulation_properties">
            <Element name="properties" />
            <div className="evaulation_properties_title">
              <div>Properties</div>
            </div>

            <div className="property_map_single_item property_map_single_item_header">
              <div className="map_single_item_img"></div>
              <div className="map_single_item_name">Property</div>
              <div className="map_single_item">Type</div>
              <div className="map_single_item">Brand</div>
              <div className="map_single_item">Sport</div>
              <div className="map_single_item">Market</div>
              <div className="map_single_item">Region</div>
              <div className="map_single_item_rosi">ROSI</div>
              <div className="map_single_item_arrow"></div>
            </div>
            {!!propertiesData.length &&
              propertiesData.map((item) => {
                const {
                  sponsorship_property,
                  type,
                  logo_url,
                  sport,
                  market,
                  region,
                  id,
                  first_brand_name,
                  latest_rosi_score
                } = item;
                const brand = first_brand_name ? first_brand_name : '/';
                const rosi = latest_rosi_score ? latest_rosi_score : '/';
                return (
                  <PropertyMapSingleItem
                    imgsrc={logo_url}
                    name={sponsorship_property}
                    type={type}
                    brand={brand}
                    sport={sport}
                    market={market}
                    region={region}
                    ROSI={rosi}
                    id={id}
                    key={id}
                  />
                );
              })}
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const { loading, propertyMapData } = state.propertyMap;

  return {
    searchData: propertyMapData,
    loading,
  };
};

export default connect(mapStateToProps, { propertyMapSearchData })(PropertyMap);

const PropertyMapSingleItem = ({
  imgsrc,
  name,
  type,
  brand,
  sport,
  market,
  region,
  ROSI,
  id,
}) => {
  return (
    <Link to={`/kindred-property/${id}`}>
      <div className="property_map_single_item">
        <div className="map_single_item_img">
          <img src={imgsrc} />
        </div>
        <div className="map_single_item_name">{name}</div>
        <div className="map_single_item">{type}</div>
        <div className="map_single_item">{brand}</div>
        <div className="map_single_item">{sport}</div>
        <div className="map_single_item">{market}</div>
        <div className="map_single_item">{region}</div>
        <div
          className="map_single_item_rosi"
          style={{ color: ROSI < 1 && '#eb462b' }}>
          {ROSI}
        </div>
        <div className="map_single_item_arrow">{'>'}</div>
      </div>
    </Link>
  );
};
