import React from 'react'
import { Link } from "react-router-dom"
import group from 'assets/images/group.png';
import kindred_colors from 'assets/images/kindred.png';
import kindred_logo from 'assets/images/kindred-logo.png';
import './ResetPasswordEmail.less'

const ResetPasswordEmail = () => {

    return (
        <div className='reset_password'>
            <img src={kindred_colors} className='kindred_colors' />
            <img src={group} className='group' />
            <div className='reset_password_elements'>

                <img src={kindred_logo} className='kindred_logo' />
                <div className='reset_password_name'>Reset Password</div>
                <div className='reset_password_explanation'>
                    Please email xxxxx@xxxxx.com to retrieve / reset your password
                </div>
                <div className='remember_password'>Remember your password?
                {' '}<Link to='/'>Back to login</Link> </div>

            </div>
        </div>
    )
}

export default ResetPasswordEmail;
