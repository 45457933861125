import React from 'react'
import { Bubble } from 'react-chartjs-2'


const BubleChartPropertyAnalysis = ({ data }) => {

    const dataShow = !!data.length
    const maxValX = (Math.max(...data.map((el) => el.data[0].x)))
    const maxValY = Math.max(...data.map((el) => el.data[0].y))

    const roundX = dataShow && maxValX > 0 ? (Math.ceil((maxValX * 1.1) / 1000000)) : 14
    const roundY = dataShow && maxValY > 0 ? (Math.ceil((maxValY * 1.1) / 1000000)) : 14

    const options = {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        scaleShowLabels: false,
        centerText: {
            display: true,
            text: 'dfs'
        }, legend: {
            display: false
        },
        scales: {
            yAxes: [{
                gridLines: {
                    display: true,
                },
                ticks: {
                    max: (roundY) * 1000000,
                    display: true,
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return value !== 0 ? ` £${value / 1000000}m` : '';
                    },
                    fontFamily: 'Factoria',
                    fontSize: 20,
                    fontStyle: 'bold',
                    fontColor: 'black'
                }
            }],
            xAxes: [{
                barPercentage: 1.0,
                gridLines: {
                    display: false,
                }, ticks: {
                    max: (roundX) * 1000000,
                    display: true,
                    beginAtZero: true,
                    fontSize: 20,
                    callback: function (value, index, values) {
                        return value !== 0 ? `${value / 1000000} ` : '';
                    },
                    fontFamily: 'Factoria',
                    fontSize: 20,
                    fontStyle: 'bold',
                    fontColor: 'black'
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'center',
                align: 'center',
                color: '#fff',
                formatter: function (value) {
                    return `${value.val}`;
                },
                font: {
                    family: 'Factoria',
                    color: 'white',
                    weight: 'bold',
                    size: 24
                }
            }
        }
    }

    return (
        <div>
            <Bubble
                data={{ datasets: data }}
                width={1200}
                height={850}
                options={options}
            />
        </div>
    )
}
export default BubleChartPropertyAnalysis