import { PROPERTIES } from '../actions/types'

const initialState = {
    properties: [],
    loading: true,
}


export default function (state = initialState, action) {

    const { type, payload } = action
    switch (type) {
        case PROPERTIES:
            return {
                loading: false,
                properties: payload
            }

        default:
            return state
    }

}