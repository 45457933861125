import React, { useState } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import GlobalAssetReach from './GlobalAssetReach/GlobalAssetReach';
import LocalAssetReach from './LocalAssetReach/LocalAssetReach';
import SponsorshipCutThrough from './SponsorshipCutThrough/SponsorshipCutThrough';
import BrandAssociation from './BrandAssociation/BrandAssociation';
import BrandImpact from './BrandImpact/BrandImpact';

import { detectNumColor, detectTabColor } from '../../../utils/detectColor';
import { detectPerformance } from '../../../utils/detectPerformance';

import './StrategicPerformance.less';

const { TabPane } = Tabs;

const StrategicPerformance = ({ strategicPerformance, loading }) => {

  const { value, max_value } = strategicPerformance?.overall_performance || {};
  const {
    global_asset_reach,
    local_asset_reach,
    sponsorship_cut_through,
    brand_association,
    brand_impact,
  } = strategicPerformance?.performance_breakdown || {};


  const [currentTabNum, setCurrentTabNum] = useState(global_asset_reach?.value);

  const callback = (key) => {
    const prop = JSON.parse(key);
    setCurrentTabNum(prop.numColor);
  };
 
  if (!strategicPerformance) return null;

  const detectDisclaimer = (prop) => {
    const base_met = strategicPerformance.performance_breakdown[prop]?.items.map(el => el.base_met)
    return base_met.includes(true) ? <sup
      style={{
        color: 'red'
      }}>
      *
  </sup> : ''
  }
  return (
    !loading && (
      <div className="intangible_value shadow">
        <div>Strategic Performance</div>
        <OverallPerformance value={value} maxValue={max_value} />
        <div>Performance Breakdown</div>
        <div
          className={`intangible_values_tabs arrow_sign ${detectTabColor(currentTabNum)}`}>
          <Tabs defaultActiveKey={'asdf'} onChange={callback}>
            <TabPane
              tab={
                <TabStyle
                  num={Math.round(global_asset_reach?.value)}
                  name={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>Global Asset Reach{detectDisclaimer('global_asset_reach')}</div>
                    </div>
                  }
                />
              }
              key={JSON.stringify({
                route: 'global_asset_reach',
                numColor: global_asset_reach?.value,
              })}>
              <GlobalAssetReach dataVal={global_asset_reach} />
            </TabPane>
            <TabPane
              tab={
                <TabStyle
                  num={Math.round(local_asset_reach?.value)}
                  name={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>Local Asset Reach{detectDisclaimer('local_asset_reach')}</div>
                    </div>
                  }
                />
              }
              key={JSON.stringify({
                route: 'local_asset_reach',
                numColor: local_asset_reach?.value,
              })}>
              <LocalAssetReach dataVal={local_asset_reach} />
            </TabPane>
            <TabPane
              tab={
                <TabStyle
                  num={Math.round(sponsorship_cut_through?.value)}
                  name={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>Sponsorship Cut Through{detectDisclaimer('sponsorship_cut_through')}</div>
                    </div>
                  }
                />
              }
              key={JSON.stringify({
                route: 'sponsorship_cut_through',
                numColor: sponsorship_cut_through?.value,
              })}>
              <SponsorshipCutThrough dataVal={sponsorship_cut_through} />
            </TabPane>
            <TabPane
              tab={
                <TabStyle
                  num={Math.round(brand_association?.value)}
                  name={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>Brand Association{detectDisclaimer('brand_association')}</div>
                    </div>
                  }
                />
              }
              key={JSON.stringify({
                route: 'brand_association',
                numColor: brand_association?.value,
              })}>
              <BrandAssociation dataVal={brand_association} />
            </TabPane>
            <TabPane
              tab={
                <TabStyle
                  num={Math.round(brand_impact?.value)}
                  name={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>Brand Impact{detectDisclaimer('brand_impact')}</div>
                    </div>
                  }
                />
              }
              key={JSON.stringify({
                route: 'brand_impact',
                numColor: brand_impact?.value,
              })}>
              <BrandImpact dataVal={brand_impact} />
            </TabPane>
          </Tabs>
        </div>
        <div className="strategic_key_description">
          <div className="strategic_key_name">Key:</div>
          <div className="num_name_wrap">
            <div className="perf_num weak_perf_num">{`<`}95</div>
            <div className="perf_name">Weak Performance</div>
          </div>
          <div className="num_name_wrap">
            <div className="perf_num average_perf_num">95-105</div>
            <div className="perf_name">Average Performance</div>
          </div>
          <div className="num_name_wrap">
            <div className=" perf_num strong_perf_num">105-140</div>
            <div className="perf_name">Strong Performance</div>
          </div>
          <div className="num_name_wrap">
            <div className=" perf_num very_perf_num">≥140</div>
            <div className="perf_name">Very Strong Performance</div>
          </div>
        </div>
        <div className="disclaimer">
          <span style={{ color: 'red' }}><sup>⋆</sup>Disclaimer</span>: The response group
          has a base size of less than 50, therefore this data is indicative
          rather than conclusive.
        </div>
      </div>
    )
  );
};
const mapStateToProps = (state) => {
  const { loading } = state.property;

  return {
    strategicPerformance: state.property.strategicPerformance,
    loading,
  };
};

export default connect(mapStateToProps, null)(StrategicPerformance);

const OverallPerformance = ({ value, maxValue }) => {
  const greenPercentage = `${value / 2}%`;
  const strongPerformanceMargin = `${value / 2 - 5}%`;
  return (
    <>
      <div className="overall_performance">
        <div className="strong_performance">
          <div className="overall_performance_name">Overall Performance</div>
          <div
            className="strong_performance_name"
            style={{ marginLeft: strongPerformanceMargin }}>
            <div style={{ color: detectNumColor(value) }}>{value && value}</div>
            <div style={{ width: 'max-content' }}>{detectPerformance(value)}</div>
            <div></div>
          </div>
        </div>
        <div className="over_all_percentage_wrapper">
          {/* <div className='inner_lines'></div>
                <div className='inner_lines'></div>
            <div className='inner_lines'></div> */}
          <div
            className="over_all_percentage_green"
            style={{ width: greenPercentage, backgroundColor: detectNumColor(value) }}></div>
        </div>
      </div>
    </>
  );
};

const TabStyle = ({ num, name }) => {
  return (
    <div className="intangible_tab_style">
      <div style={{ color: detectNumColor(num) }}>{num}</div>
      <div>{name}</div>
    </div>
  );
};
