import React, { useState, useEffect } from 'react'
import { Icon, Input, Checkbox, Select } from 'antd'
import { http } from 'services/http'
import qs from "qs"
import HorizontalBarChart from '../../ChartComponents/HorizontalBarChart'
import formatNumber from 'utils/formatNumber'
import tvIconInactive from 'assets/icons/TVinactive.svg'
import socialIconInactive from 'assets/icons/SocialInactive.svg'
import onlineIconInactive from 'assets/icons/OnlineInactive.svg'
import printIconInactive from 'assets/icons/PrintInactive.svg'

import './AssetAnalysis.less'

const { Search } = Input;
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

const AssetAnalysis = ({ match, location }) => {

    const [fetched, setFetched] = useState({ properties: [], data: {} })
    const [dataForSearch, setdataForSearch] = useState({ type: ['By Property', 'By Asset'], properties: [], filter: [] })
    const [queryData, setQueryData] = useState({ filter: [], media: [], type: '', properties: '' })
    const [chartData, setChartData] = useState([])
    const [seasonsList, setSeasonsList] = useState({ list: [], selected: '' })
    const [animation, setAnimation] = useState(false)


    useEffect(() => {
        fetchSeasons()
    }, [])

    async function fetchSeasons() {

        const res = await http.get('/seasons/distinct-years')
        if (res.data?.data) {
            setSeasonsList({ ...seasonsList, list: res.data?.data })
        }
    }

    async function fetchPropertySearchData() {
        const season = getCurrentSeason()
        const res = await http.get(`/properties/asset/byproperty/filters?${qs.stringify(season)}`)
        const properties = Object.keys(res.data.data);
        setFetched({ properties, data: res.data.data })
        setdataForSearch({ ...dataForSearch, properties })
    }

    async function fetchAssetSearchData() {
        const season = getCurrentSeason()
        const res = await http.get(`/properties/asset/byasset/filters?${qs.stringify(season)}`)
        const properties = Object.keys(res.data.data);
        setFetched({ properties, data: res.data.data })
        setdataForSearch({ ...dataForSearch, properties })
    }

    async function fetchChartDataByProperty() {
        const { start_year, end_year } = getCurrentSeason()
        const data = (property, media, assets) => {
            return {
                start_year,
                end_year,
                property,
                media,
                assets
            }
        }

        const res = await http.post('/properties/asset/byproperty/graph', data(queryData.properties, queryData.media, queryData.filter))
        setChartData(dataForChart(res.data.data))
    }

    async function fetchChartDataByAsset() {
        const { start_year, end_year } = getCurrentSeason()
        const data = (asset, media, properties) => {
            return {
                start_year,
                end_year,
                asset,
                media,
                properties
            }
        }
        const res = await http.post('/properties/asset/byasset/graph', data(queryData.properties, queryData.media, queryData.filter))
        setChartData(dataForChart(res.data.data))
    }

    useEffect(() => {
        setFetched({ properties: [], data: {} })
        searchDataQuery('', 'properties')
        setQueryData({ ...queryData, filter: [], media: [], properties: '' })
        if (queryData.type === 'By Property') {
            fetchPropertySearchData()
        }

        if (queryData.type === 'By Asset') {
            fetchAssetSearchData()
        }
    }, [queryData.type])

    function onFilterChange(item) {
        setQueryData({ ...queryData, filter: item })
    }

    const getCurrentSeason = () => {

        const getObj = (el) => {
            const [start_year, end_year] = el.split('-')
            return {
                start_year,
                end_year
            }
        }
        if (!!seasonsList.selected) {
            return getObj(seasonsList.selected)
        } else {
            return seasonsList.list[0]
        }
    }

    const setQuery = (queryName, item) => {
        if (queryName === 'media') {
            setQueryData({ ...queryData, [queryName]: [item], filter: [] })
            const mediaFilterSearch = fetched.data[queryData.properties][item]
            if (mediaFilterSearch) setdataForSearch({ ...dataForSearch, filter: mediaFilterSearch })
        }
        if (queryName === 'property' || queryName === 'type') {
            setQueryData({ ...queryData, [queryName]: item })
        }

    }
    const setAllMedia = () => {
        const mediaFilterSearch = Object.keys(fetched.data[queryData.properties])

        if (!!mediaFilterSearch.length) {
            let allProp = []
            mediaFilterSearch.forEach(el => {
                allProp = [...allProp, ...fetched.data[queryData.properties][el]]
            })
            setQueryData({ ...queryData, media: mediaFilterSearch, filter: [] })
            setdataForSearch({ ...dataForSearch, filter: [...new Set(allProp)] })
        }
    }

    const startAnalysis = () => {
        // const queryString = qs.stringify(queryData);
        // history.push(`${url}?${queryString}`);
        if (queryData.type === 'By Property') fetchChartDataByProperty()
        if (queryData.type === 'By Asset') fetchChartDataByAsset()
    }

    const onMediaChange = (media) => {
    }

    const removeQueryParam = (item, propType) => {
        setQueryData({ ...queryData, [propType]: '' });
        // history.push(`${url}?${qs.stringify(newQuery)}`);
    };

    const searchDataQuery = (str, propType) => {
        if (str.length > 0) {
            let res = fetched.properties.filter((item) => item.toLowerCase().includes(str.toLowerCase()));
            setdataForSearch({ ...dataForSearch, [propType]: res });
        } else {
            setdataForSearch({ ...dataForSearch, [propType]: fetched.properties });
        }
    };

    const setQueryParam = (item, propType) => {
        const newQuery = {
            ...queryData,
            [propType]: item,
            media: [],
            filter: []
        };
        setQueryData(newQuery);
        // history.push(`${url}?${qs.stringify(newQuery)}`);
    };

    const selectAll = () => {
        setQueryData({ ...queryData, filter: dataForSearch.filter })
    }
    const clearSelection = () => {
        setQueryData({ ...queryData, filter: [] })
    }

    const StartSetUp = () => {
        return <div className='start_set_up_box'>
            <div className='start_set_up_box_text'>Follow the Chart Set Up's steps on the left to start your asset analysis.</div>
            <div className='reset_all_filters' style={{ width: '290px' }} onClick={fadeAnimation}>Start Set Up</div>
        </div>
    }


    const dataForChart = (qi_media_value) => {
        const backgroundColors = {
            tv: '#00a5de',
            print: '#f39000',
            online: '#e72b78',
            'social media': '#992785'
        }

        const maxScore = (() => {
            const score = Math.max(...qi_media_value.map(el => el.price))
            return Math.round(score * 1.1)
        })()

        const data = qi_media_value.map(el => {
            const { datasets, name, price } = el;
            const newDataset = datasets.map(item => {
                item.backgroundColor = backgroundColors[item.label.toLowerCase()]
                return item
            })
            return {
                align: 'start',
                preffix: '£',
                suffix: '',
                barPercentage: 0.8,
                maxVal: maxScore,
                width: 520,
                datasets: newDataset,
                name,
                price
            }

        })
        return data
    }

    const resetChart = () => {
        setFetched({ properties: [], data: {} })
        setdataForSearch({ type: ['By Property', 'By Asset'], properties: [], filter: [] })
        setQueryData({ filter: [], media: [], type: '', properties: '' })
        setChartData([])
    }
    const formatSeasonYears = (y) => {
        return `${y.start_year}-${y.end_year}`
    }

    const handleChange = (el) => {
        resetChart()
        let data = { ...seasonsList, selected: el }
        setSeasonsList(data)
    }
    const fadeAnimation = () => {
        setAnimation(true)
        setTimeout(() => { setAnimation(false) }, 1500)
    }

    return (
        <div className='sponsorship_evaulation_layout'>
            <div className='sponsorship_evaulation_filter'>
                <div className={` ${animation && 'animation_fade'}`}></div>
                <div style={{ margin: '40px' }}>
                    <div className='chart_set_up'>Chart Set Up</div>
                    <div className='reset_chart' onClick={resetChart}> <Icon type="reload" style={{ marginRight: '4px' }} />Reset Chart</div>
                    <div className='search_property'>1. Select Season  </div>
                    <div className='search_property_description'>Choose a specific season to display the related assets:</div>
                    {!!seasonsList?.list.length && <Select
                        defaultValue={formatSeasonYears(seasonsList.list[0])}
                        style={{ width: '100%', height: '30px' }}
                        className="selection"
                        onChange={handleChange}>
                        {seasonsList.list.map(el => {
                            let season = formatSeasonYears(el)
                            return <Option key={season} value={season}>Season {season}</Option>
                        })}
                    </Select>}
                    {!!seasonsList.list.length && <><div className='search_property'>2. Select Analysis Type </div>
                        <div className='search_property_description'>Select a type of asset analysis:</div>
                        <div className='sponsorship_evaulation_filter_results'>
                            {dataForSearch.type.map(item => {
                                return <div key={item} className={`filter_item ${(queryData.type && queryData.type === item) && 'conditional_render'}`} onClick={() => setQuery('type', item)}>{item}</div>
                            })}
                        </div></>}
                    {!!fetched.properties.length && <>

                        {queryData.type === 'By Property' ?
                            <> <div className='search_property'>3. Select Properties </div>
                                <div className='search_property_description'>Search a property by its name to <br /> display its assets:</div>
                            </> :
                            <>
                                <div className='search_property'>3. Select Assets </div>
                                <div className='search_property_description'>Search an asset by its name to display <br /> the available properties:</div>
                            </>
                        }
                        <div>
                            {queryData['properties'] && !!queryData['properties'].length && (
                                <div className="sponsorship_evaulation_filter_results">
                                    {queryData['properties'] && (
                                        <div
                                            className="filter_item filter_item_selected"
                                            onClick={() => removeQueryParam(queryData['properties'], 'properties')}>
                                            {queryData['properties']}
                                            <Icon
                                                type="close"
                                                style={{ fontSize: '11px', marginLeft: '12px' }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="filter_search_input_field">
                                <Search
                                    placeholder="Start typing"
                                    onChange={(e) => searchDataQuery(e.target.value, 'properties')}
                                />
                            </div>
                            <div className="sponsorship_evaulation_filter_results search_results">
                                {dataForSearch['properties'] &&
                                    dataForSearch['properties'].map((item) => {
                                        return (
                                            <div
                                                key={item}
                                                className="filter_item"
                                                onClick={() => setQueryParam(item, 'properties')}>
                                                {item}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                        {queryData.properties && <><div className='search_property'>3. Filter Selection </div>
                            <div className='search_property_description'>Use the below filters to refine your analysis:</div>
                            <div className='filter_box_wrap'>
                                <MediaFilterBox title={'All Media'} styled={queryData.media && queryData.media.length == Object.keys(fetched.data[queryData.properties]).length} block={!!Object.keys(fetched.data[queryData.properties]).length} click={() => setAllMedia()} />
                                <div>
                                    <MediaFilterBox title={<div><img src={tvIconInactive} /><div>TV</div></div>} styled={queryData.media && queryData.media.includes('TV')} block={fetched.data[queryData.properties]['TV']} click={() => setQuery('media', 'TV')} />
                                    <MediaFilterBox title={<div><img style={{ width: '14px' }} src={printIconInactive} /><div>Print</div></div>} styled={queryData.media && queryData.media.includes('Print')} block={fetched.data[queryData.properties]['Print']} click={() => setQuery('media', 'Print')} />
                                    <MediaFilterBox title={<div><img style={{ width: '16px' }} src={onlineIconInactive} /><div>Online</div></div>} styled={queryData.media && queryData.media.includes('Online')} block={fetched.data[queryData.properties]['Online']} click={() => setQuery('media', 'Online')} />
                                    <MediaFilterBox title={<div><img style={{ width: '12px' }} src={socialIconInactive} /><div>Social</div></div>} styled={queryData.media && queryData.media.includes('Social Media')} block={fetched.data[queryData.properties]['Social Media']} click={() => setQuery('media', 'Social Media')} />
                                </div>
                            </div>

                            {!!queryData.media.length &&
                                <>
                                    <div className='asset_filter'>Asset Filter</div>
                                    <div className='check_box_options'>
                                        <CheckboxGroup
                                            options={dataForSearch.filter}
                                            value={queryData.filter}
                                            onChange={onFilterChange}
                                        />
                                    </div>
                                    <div className='filter_select_clear_wrap'>
                                        <div className='filter_select_clear_button' onClick={selectAll}>Select All</div>
                                        <div className='filter_select_clear_button' onClick={clearSelection}>Clear Selection</div>
                                    </div>
                                </>}
                        </>
                        }
                    </>
                    }
                    <div className='reset_all_filters' style={!queryData.filter.length ? { backgroundColor: 'rgba(189, 189, 189, 0.5)', pointerEvents: 'none' } : {}} onClick={startAnalysis}>Start Analysis</div>
                </div>
            </div>
            <div className='sponsorship_evaulation_result'>
                <div className='asset_analysis'>
                    <div className='asset_name_with_keys'>
                        <div className='asset_analysis_name'>Asset Analysis</div>
                        {!!chartData.length && <div style={{ marginTop: '15px' }}>
                            <div className='breakdown_by_location_key_description' >
                                <div className='breakdown_key_name'>Key:</div>
                                <div className='single_breakdown_wrap' onClick={() => (onMediaChange('Social'))} style={{ cursor: 'pointer' }}>
                                    <div className='breakdown_rectangle social_name'></div>
                                    <div className='breakdown_name'>Social</div>
                                </div>
                                <div className='single_breakdown_wrap' onClick={() => (onMediaChange('Online'))} style={{ cursor: 'pointer' }}>
                                    <div className='breakdown_rectangle online_name'></div>
                                    <div className='breakdown_name'>Online</div>
                                </div>
                                <div className='single_breakdown_wrap' onClick={() => (onMediaChange('Print'))} style={{ cursor: 'pointer' }}>
                                    <div className='breakdown_rectangle print_name'></div>
                                    <div className='breakdown_name'>Print</div>
                                </div>
                                <div className='single_breakdown_wrap' onClick={() => (onMediaChange('TV'))} style={{ cursor: 'pointer' }}>
                                    <div className='breakdown_rectangle tv_name'></div>
                                    <div className='breakdown_name'>TV</div>
                                </div>
                            </div>
                        </div>}
                    </div>
                    {!!chartData.length ? <div className='breakdown_by_locations' style={{ display: 'flex', flexFlow: 'column', marginLeft: '60px', marginTop: '30px' }}>
                        <SingleBar>
                            <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}><div>QI Media Value</div></div>
                        </SingleBar>
                        {chartData.map((el) => {
                            return <SingleBar key={el.name} name={el.name} price={`£${formatNumber(Math.round(el.price))}`} >
                                <HorizontalBarChart dataVal={el} />
                            </SingleBar>
                        })}
                    </div> :
                        <StartSetUp />}
                </div>
            </div>
        </div >
    )
}

export default AssetAnalysis

const MediaFilterBox = ({ title, click, styled, block }) => {
    const blocked = {
        pointerEvents: 'none',
        backgroundColor: '#dbdbdb'
    }
    return (
        <div className={`media_filter_box ${styled && 'conditional_render'}`} style={block ? {} : blocked} onClick={click}>
            {title}
        </div>
    )
}

const SingleBar = (props) => {

    const { name, price } = props

    return <div className='asset_analysis_chart_bar'>
        <div>{name}</div>
        <div>{props.children}</div>
        <div>{price}</div>
    </div>
}
