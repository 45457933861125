import React from 'react'
import { Tabs } from 'antd';

import {
    Route,
    Switch,
    useHistory,
    useParams,
    Redirect,
} from 'react-router-dom';
import { connect } from "react-redux"

import DataUpload from 'components/Admin/DataUpload/DataUpload'
import UserManagement from 'components/Admin/UserManagement/UserManagement'

import './Admin.less'
import PropertyManagement from "./PropertyManagement/PropertyManagement";
const { TabPane } = Tabs;

const Admin = ({ role, adminPermission, adminManagement, adminUpload, propertyManagement }) => {
    let history = useHistory();
    let { admin_cat } = useParams();
    const callback = (route) => {
        history.push(`/admin/${route}`);
    }
    return (
        <div className='admin'>
            <div className='admin_name'>Admin</div>
            {(adminUpload && adminManagement) && <div className='admin_tabs'>
                <div className='admin_tabs_elements'>
                    <Tabs
                        defaultActiveKey={admin_cat}
                        onChange={callback}
                        className="tabs tabs_nav">
                        <TabPane
                            tab="Data upload"
                            key="data-upload"
                            style={{ display: 'none' }}
                        />
                        <TabPane
                            tab="User management"
                            key="user-management"
                            style={{ display: 'none' }}
                        />
                        {propertyManagement && <TabPane
                            tab="Property Management"
                            key="property-management"
                            style={{ display: 'none' }}
                        />}
                    </Tabs>
                </div>
            </div>}

            <Switch>
                {adminUpload && <Route
                    path="/admin/data-upload"
                    render={() => <DataUpload adminPermission={adminPermission} />}
                />}
                {adminManagement && <Route
                    path="/admin/user-management"
                    render={() => <UserManagement role={role} />}
                />}
                {propertyManagement && <Route
                    path="/admin/property-management"
                    render={() => <PropertyManagement role={role} />}
                />}
                <Route
                    render={() => <Redirect to={`/admin/${adminUpload && 'data-upload' || adminManagement && 'user-management'}`} />}
                />
            </Switch>
        </div>
    )
}
const mapStateToProps = (state) => ({
    role: state.auth?.user?.role,
    adminPermission: state.auth?.adminPermission,
    adminManagement: state.auth?.adminPermission?.adminManagement,
    adminUpload: state.auth?.adminPermission?.adminUpload,
    propertyManagement: state.auth?.adminPermission?.propertyManagement

});

export default connect(mapStateToProps)(Admin)
