import React, {useState} from 'react';
import './UserProfile.less';
import Button from "antd/es/button";
import {Form, Input, notification} from "antd";
import {http} from "../../services/http";
import {connect} from "react-redux";
import moment from "moment";
import {logout} from "../../redux/actions/auth";


const UserProfile = ({form, user, logout}) => {
    const { getFieldDecorator, validateFieldsAndScroll, getFieldValue } = form;
    const [loading, setLoading] = useState(false)

    const validateConfirmPassword = (rule, value, callback) => {

        if (value !== getFieldValue('new_password')) {
            callback('Confirm new password and new password doesn\'t match');
        }

        callback();
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);
                http.put('/users/change-password', values).then(() => {
                    notification.success({ message: 'Password is changed'});
                    logout();
                }).catch((err) => {
                    let errors = err.response?.data?.errors ? Object.keys(err.response.data.errors) : '';
                    let message = err.response?.data?.message;
                    if (errors) {
                        errors.forEach(error => {
                            notification.warning({ message: err.response.data.errors[error] });
                        })
                    } else if(message) {
                        notification.warning({ message: message });
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }
        })
    }
    return (
        <div className='profile_wrapper'>
            <div className='title'>My profile</div>
            <div className="profile_body">
                <div className='profile'>
                    <div className='details'>
                        <div className='details_title'>
                            Profile details
                        </div>
                        <div className='data'>
                            <div className='label'>Full name</div><div className='value'>
                            {user.first_name} {user.last_name}
                        </div>
                        </div>
                        <div className='data'>
                            <div className='label'>Email</div><div className='value'>{user.email}</div>
                        </div>
                        <div className='data'>
                            <div className='label'>Last updated</div><div className='value'>
                                {moment(user.updated_at).format('DD/MM/YYYY HH:mm')}
                        </div>
                        </div>
                    </div>
                    <div className='logout_wrapper'>
                        <Button className='logout_button' onClick={() => logout()}>Logout</Button>
                    </div>
                </div>
                <div className='form_wrapper'>
                    <div className="change_password_title">Change Password</div>
                    <div className='form_elements'>
                        <Form  className='user_form' onSubmit={handleSubmit}>
                            <div className="input_wrapper">
                                <div className='label'>Current Password</div>
                                <div className='label'>New Password</div>
                                <div className='label'>Confirm New Password</div>
                            </div>
                            <div className='input_wrapper'>
                                <Form.Item className='password_field'>
                                    {getFieldDecorator('current_password', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Current password is required!"
                                                },
                                            ]
                                        }

                                    )(
                                        <Input.Password
                                            type="password"
                                            placeholder="Current password"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item className='password_field'>
                                    {getFieldDecorator('new_password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'New password is required!'
                                            },
                                            {
                                                pattern: new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,15}$"),
                                                message: "Required 8 to 15 letters, at least one number, one lowercase and uppercase letter and one special character!"
                                            }
                                        ],
                                    })(<Input.Password placeholder="New password" type="password" />)}
                                </Form.Item>
                                <Form.Item className='password_field'>
                                    {getFieldDecorator('confirm_new_password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Confirm new password is required!'
                                            },
                                            {
                                                validator : validateConfirmPassword,
                                            }
                                        ]
                                    })(<Input.Password placeholder="Confirm new password" type="password" />)}
                                </Form.Item>
                            </div>
                            <Button key="submit" type="primary" htmlType='submit' loading={loading} className='change_password_btn'>
                                Change Password
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>

        </div>

    );
};
const mapStateToProps = (state => ({
    user: state.auth.user
}))
export default connect(mapStateToProps, {logout})(Form.create()(UserProfile));
