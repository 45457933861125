import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import qs from 'qs';
import { connect } from "react-redux"
import brand_hits from 'assets/icons/Methodology - brand hits.svg'
import size from 'assets/icons/Methodology - size.svg'
import duration from 'assets/icons/Methodology - duration.svg'
import impact from 'assets/icons/Methodology - impact.svg'
import location from 'assets/icons/Methodology - location.svg'
import sales_impact from 'assets/icons/methodology - rosi.svg'
import tagible_sales_value from 'assets/icons/tagible_sales_value.png'

import './Methodology.less'

const Methodology = ({ location, methodology }) => {

    const { kindred_scope, qi_media_evaluation_methodology, rosi_model_methodology } = methodology
    let history = useHistory();
    const [tab, setTab] = useState('kindred')

    useEffect(() => {
        checkForRedirect()
    }, [])

    const checkForRedirect = () => {
        let searchQuery = qs.parse(location.search.substring(1))
        if (searchQuery.tab === 'rosi') {
            activeTab('rosi')
            history.push('/methodology')
        }
    }

    const activeTab = (tab) => {
        setTab(tab)

        scroller.scrollTo(tab, {
            duration: 500,
            delay: 10,
            smooth: true,
            offset: -10,
        });
    }

    return (
        <div className='methodology'>
            <div className='methodology_tabs'>
                {kindred_scope && <div className={`methodology_tab ${tab === 'kindred' && 'methodology_tab_active'}`} onClick={() => activeTab('kindred')}>Kindred Scope 2019/20</div>}
                {qi_media_evaluation_methodology && <div className={`methodology_tab ${tab === 'qi' && 'methodology_tab_active'}`} onClick={() => activeTab('qi')}>QI Media Evaluation Methodology</div>}
                {rosi_model_methodology && <div className={`methodology_tab ${tab === 'rosi' && 'methodology_tab_active'}`} onClick={() => activeTab('rosi')}>ROSI Model Methodology</div>}
            </div>
            <div className='methodology_data'>

                {kindred_scope && <Element name='kindred'>
                    <KindredScope />
                </Element>}

                {qi_media_evaluation_methodology && <Element name='qi'>
                    <QIEvaluation />
                </Element>}

                {rosi_model_methodology && <Element name='rosi'>
                    <RosiModelMethodology />
                </Element>}

            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    methodology: state.auth?.methodology
});

export default connect(mapStateToProps)(Methodology)

const KindredScope = () => {

    const singleItem = (data, i) => {

        const { Market, Sponsorship, Reporting } = data
        const TVMarket = data['TV Market']
        const TVSampleAnalysisorFull = data['TV Sample Analysis or Full']
        const Onlinehostmarket = data['Online (host market)']
        const PrintHostMarket = data['Print (Host Market)']
        const Social = data['Social (right holder & key media)']

        return <div key={i} className='kindred_scope_single_list_item'>
            <div>{Market === 'No' ? '-' : Market}</div>
            <div>{Sponsorship === 'No' ? '-' : Sponsorship}</div>
            <div>{TVMarket === 'No' ? '-' : TVMarket}</div>
            <div>{TVSampleAnalysisorFull === 'No' ? '-' : TVSampleAnalysisorFull}</div>
            <div>{Onlinehostmarket === 'No' ? '-' : Onlinehostmarket}</div>
            <div>{PrintHostMarket === 'No' ? '-' : PrintHostMarket}</div>
            <div>{Social === 'No' ? '-' : Social}</div>
            <div>{Reporting === 'No' ? '-' : Reporting}</div>
        </div>
    }

    return <div className='kindred_scope'>
        <div className='kindred_scope_year'>Kindred Scope 2019/20</div>
        {/* <div className='kindred_scope_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</div> */}
        <div className='kindred_scope_list'>

            {kindredScope.map((item, i) => {
                return singleItem(item, i)
            })}

        </div>
    </div>
}

const QIEvaluation = () => {
    return <div className='qi_evaluation_methodology'>
        <div className='qi_methodology_name'>QI Media Evaluation Methodology</div>
        <div className='qi_methodology_text'>The QI media evaluation methodology is applied across all media platforms; TV, print, online and social media, which makes up the total tangible value.</div>
        <div className='meda_value_quality_wrap'>
            <div>
                <div className='media_value_name'>1. QI Media Value</div>
                <div className='media_value_text'>To find the QI Media Value, we first calculate how much it costs to reach the viewing audience of the broadcast via traditional advertising. Then, we find what is the adjusted media value of this exposure for the brand.</div>
                <div className='step_one'>STEP 1</div>
                <div className='meth_box_regular'>
                    Viewership <div className='multiplication_sign'>X</div> Cost of media <div className='equals_sign'>=</div> CPT Spot:30 Rate
                </div>
                <div className='meth_box_purple'>
                    11.3m individuals <div className='multiplication_sign'>X</div> £25.39 CPT <div className='equals_sign'>=</div> £285,713
                </div>
                <div className='step_one'>STEP 2</div>
                <div className='meth_box_regular' style={{ width: '337px' }}>
                    {dividerBox('CPT Spot :30 Rate', '30 seconds')}
                    <div className='equals_sign'>=</div> Cost per second
                </div>
                <div className='meth_box_purple' style={{ width: '337px' }}>
                    {dividerBox('£285,713', '30 seconds')}
                    <div className='equals_sign'>=</div> £9,524
                </div>
                <div className='step_one'>STEP 3</div>
                <div className='meth_box_regular' style={{ width: '566px' }}>
                    Cost per second <div className='multiplication_sign'>X</div> On screen duration <div className='equals_sign'>=</div> 100% media equivalency
                </div>
                <div className='meth_box_purple' style={{ width: '566px' }}>
                    £9,524 <div className='multiplication_sign'>X</div> 9 seconds <div className='equals_sign'>=</div> £85,714
                </div>
                <div className='step_one'>STEP 4</div>
                <div className='meth_box_regular' style={{ width: '566px' }}>
                    100% media equivalency <div className='multiplication_sign'>X</div> Quality index score <div className='equals_sign'>=</div> QI media value
                </div>
                <div className='meth_box_purple' style={{ width: '566px' }}>
                    £85,714 <div className='multiplication_sign'>X</div> 25.47 <div className='equals_sign'>=</div> £21.831
                </div>
            </div>
            <div>

                <div className='media_value_name'>2. Quality Index Score</div>
                <div className='media_value_text' style={{ width: '500px' }}>The Quality Index Score (QI Score) is determined using the 5 below quality indexes:</div>

                <div className='quality_index_element_wrapper'>
                    <img alt='size' src={size} />
                    <div>
                        <div className='quality_index_title'>Size</div>
                        <div className='media_value_text'>Size of the advertising message in proportion to the screen (on screen size)</div>
                    </div>
                </div>

                <div className='quality_index_element_wrapper'>
                    <img alt='location' src={location} />
                    <div>
                        <div className='quality_index_title'>Location</div>
                        <div className='media_value_text'>Proximity to the centre of the screen</div>
                    </div>
                </div>

                <div className='quality_index_element_wrapper'>
                    <img alt='duration' src={duration} />
                    <div>
                        <div className='quality_index_title'>Duration</div>
                        <div className='media_value_text'>The total duration on screen for the exposure</div>
                    </div>
                </div>

                <div className='quality_index_element_wrapper'>
                    <img alt='brand' src={brand_hits} />
                    <div>
                        <div className='quality_index_title'>No of brand hits</div>
                        <div className='media_value_text'>Number of simultaneously visible advertising messages for the brand, referred to as hits (i.e. logos and messaging) </div>
                    </div>
                </div>

                <div className='quality_index_element_wrapper'>
                    <img alt='impact' src={impact} />
                    <div>
                        <div className='quality_index_title'>Impact factor</div>
                        <div className='media_value_text'>The QI score is calculated uniquely for every individual exposure and asset </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

const RosiModelMethodology = () => {
    return <div className='qi_evaluation_methodology'>
        <div className='qi_methodology_name'>ROSI Model Methodology</div>
        <div className='qi_methodology_text'>The ROSI of a property is calculated from its tangible value and sales impact using the principle below:</div>
        <div className='rosi_methodology_wrap'>
            <div>
                <div className='media_value_name'>1. Total Value</div>
                <div className='media_value_text'>To calculate the ROSI of a property, you first need to find out its total value:</div>
                <img alt='tangible' src={tagible_sales_value} className='tagible_sales_value' />
            </div>
            <div>
                <div className='media_value_name'>2. ROSI Score</div>
                <div className='media_value_text'>Using the total value previously calculated, we can now find out the ROSI Score of a property. </div>
                <div className='meth_box_regular rosi_model_box_update'>
                    {dividerBox('Total Value', 'Kindred Investment*')}
                    <div className='equals_sign'>=</div> ROSI Score
                </div>
                <div className='media_value_text'>*Kindred investment is made up of the costs for the acquisition of rights and activation measures.</div>

                <img alt='sales' src={sales_impact} className='sales_impact_photo' />
            </div>

        </div>

    </div>
}

const dividerBox = (a, b) => {
    return <div className='divider_box'>
        <div>{a}</div>
        <div></div>
        <div>{b}</div>
    </div>

}
const kindredScope = [
    {
        "Market": "Market",
        "Sponsorship": "Sponsorship",
        "TV Market": "TV Market",
        "TV Sample Analysis or Full": "TV Analysis (Full/Sample)",
        "Online (host market)": "Online  (Host Market)",
        "Print (Host Market)": "Print (Host Market)",
        "Social (right holder & key media)": "Social (Right Holder & Key Media)",
        "Reporting": "Reporting"
    },
    {
        "Market": "Belgium",
        "Sponsorship": "Club Brugge",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "Belgium",
        "Sponsorship": "Belgian Pro League (24 teams in Div A+B)",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Sample",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "Denmark",
        "Sponsorship": "FC Copenhagen",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Sample",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "France",
        "Sponsorship": "PSG",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "Full",
        "Reporting": "End of Season"
    },
    {
        "Market": "France",
        "Sponsorship": "LED deal away games PSG & OM in Ligue1",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "UK",
        "Sponsorship": "Leeds",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Full",
        "Print (Host Market)": "Full",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Middlesborough",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Derby County",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Full",
        "Print (Host Market)": "Full",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Derby County - Wayne Rooney tracking",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full + sample of TV News",
        "Online (host market)": "Full",
        "Print (Host Market)": "Full",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Preston NE",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Glasgow Rangers",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Southampton FC",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "UK",
        "Sponsorship": "Premier League of Darts",
        "TV Market": "Key Markets",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Q4"
    },
    {
        "Market": "UK",
        "Sponsorship": "European Championship of Darts",
        "TV Market": "Key Markets",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Q2"
    },
    {
        "Market": "UK",
        "Sponsorship": "Horse Racing - Epsom Derby",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Q1"
    },
    {
        "Market": "UK",
        "Sponsorship": "Horse Racing - Cheltenham Festival",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Q3"
    },
    {
        "Market": "UK",
        "Sponsorship": "Horse Racinng - Goodwood Festival",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Q4"
    },
    {
        "Market": "Germany",
        "Sponsorship": "Red Bull Leipzig",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Sample",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "Germany",
        "Sponsorship": "Borussia Monchengladbach",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "Sample",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "Germamy",
        "Sponsorship": "Bundesliga Supercup",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "Spain",
        "Sponsorship": "LED (&3D carpet) LaLiga (RM, Barca & Atelti away games)",
        "TV Market": "Global",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "Global",
        "Sponsorship": "Astralis E-sports*",
        "TV Market": "Key Markets",
        "TV Sample Analysis or Full": "Sample",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "Quarterly"
    },
    {
        "Market": "Romania",
        "Sponsorship": "LED deal Romanian League",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Sample",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "Romania",
        "Sponsorship": "FC Dynamo Bucharest",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "End of Season"
    },
    {
        "Market": "USA",
        "Sponsorship": "New Jersey Devils",
        "TV Market": "US & Canada",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Quarterly"
    },
    {
        "Market": "Australia",
        "Sponsorship": "Sydney Roosters",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Full",
        "Online (host market)": "No",
        "Print (Host Market)": "No",
        "Social (right holder & key media)": "No",
        "Reporting": "Quarterly"
    },
    {
        "Market": "Sweden",
        "Sponsorship": "Football Allsvenskan (16 teams) and Superettan (16 teams)",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Sample",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "End of Season"
    },
    {
        "Market": "Sweden",
        "Sponsorship": "Hockey Allsvensken",
        "TV Market": "Host Market",
        "TV Sample Analysis or Full": "Sample",
        "Online (host market)": "Sample",
        "Print (Host Market)": "Sample",
        "Social (right holder & key media)": "Full",
        "Reporting": "End of Season"
    }
]