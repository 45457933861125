import React from 'react'
import { Tabs } from 'antd';
import Acquisition from './Acquisition/Acquisition'

import './PropertyPerformance.less'

const { TabPane } = Tabs;

const PropertyPerformance = () => {

    const callback = (route) => {
    }

    return (
        <div className='property_performance shadow' style={{ display: 'flex', flexFlow: 'column' }}>
            <div className='property_performance_name'>Property Performance</div>
            <div className='performance_metrics'>Performance Metrics</div>


            <div className='audience_media_tabs' style={{ position: 'relative' }}>

                <Tabs defaultActiveKey={'asdf'} onChange={callback} >
                    <TabPane tab="Acquisition" key="acquisition" >
                        <Acquisition />
                    </TabPane>
                    <TabPane tab="Retention/Engagement" key="retention_engagement">
                        Retention/Engagement
                    </TabPane>
                    <TabPane tab="Reactivation" key="reactivation">
                        Reactivation
                    </TabPane>
                    <TabPane tab="Branding" key="branding">
                        Branding
                    </TabPane>
                </Tabs>
            </div>


        </div>
    )
}

export default PropertyPerformance