import React, {useEffect, useState} from 'react';
import './ConfirmDialog.less'
import Button from "antd/es/button";
import {Modal} from "antd";

const ConfirmDialog = ({closeDialog, itemName, handleDelete, modalTitle, showDialog}) => {
    const [loading, setLoading] = useState(false);
    const confirmHandler = () => {
        setLoading(true);
        handleDelete();
    }

    useEffect(() => {
        if (!showDialog) {
            setLoading(false);
        }
    }, [showDialog]);
    return (
        <Modal
            title={modalTitle}
            visible={showDialog}
            onCancel={closeDialog}
            className="dialog_wrapper"

            footer={[
                <Button key="back" onClick={closeDialog}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={confirmHandler}>
                    Delete
                </Button>,
            ]}
        >
            <div className="item">{itemName}</div>
            <div className="message">Want to delete item?</div>
        </Modal>
    );
};

export default ConfirmDialog;
