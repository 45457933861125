import React from 'react'
import { Tabs } from 'antd';
import './BreakdownBySource.less'
import BarChart from '../../ChartComponents/BarChart'
import tvIcon from 'assets/icons/TV.svg'
import tvIconInactive from 'assets/icons/TVinactive.svg'
import socialIcon from 'assets/icons/Social.svg'
import socialIconInactive from 'assets/icons/SocialInactive.svg'
import onlineIcon from 'assets/icons/Online.svg'
import onlineIconInactive from 'assets/icons/OnlineInactive.svg'
import printIcon from 'assets/icons/Print.svg'
import printIconInactive from 'assets/icons/PrintInactive.svg'
const { TabPane } = Tabs;

const BreakdownBySource = ({ breakdown_by_source }) => {

    const { online, print, social, tv } = breakdown_by_source

    const [currentTab, setCurrentTab] = React.useState('tv')

    const callback = (media) => {
        setCurrentTab(media)
    }

    const tabsStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const audienceBar = {
        align: 'end',
        preffix: '',
        suffix: '',
        barPercentage: 0.7,
        labels: tv.audience.labels,
        dataChart: tv.audience.dataChart.map((num) => Math.round(num)),
        backgroundColor: 'rgb(0,165,222)',
        maxVal: Math.max(...tv.audience.dataChart) * 1.2,
        width: 490
    }

    const QIMediaValueTV = {
        align: 'end',
        preffix: '£',
        suffix: '',
        barPercentage: 0.7,
        labels: tv.qi_media_value.labels,
        dataChart: tv.qi_media_value.dataChart.map((num) => Math.round(num)),
        backgroundColor: 'rgb(0,165,222)',
        maxVal: Math.max(...tv.qi_media_value.dataChart) * 1.2,
        width: 490
    }

    const QIMediaValuePrint = {
        align: 'end',
        preffix: '£',
        suffix: '',
        barPercentage: 0.6,
        labels: print.qi_media_value.labels,
        dataChart: print.qi_media_value.dataChart.map((num) => Math.round(num)),
        backgroundColor: '#f39000',
        maxVal: Math.max(...print.qi_media_value.dataChart) * 1.2,
        width: 1000
    }

    const QIMediaValueOnline = {
        align: 'end',
        preffix: '£',
        suffix: '',
        barPercentage: 0.6,
        labels: online.qi_media_value.labels,
        dataChart: online.qi_media_value.dataChart.map((num) => Math.round(num)),
        backgroundColor: '#e72b78',
        maxVal: Math.max(...online.qi_media_value.dataChart) * 1.2,
        width: 1150
    }


    const QIMediaValueSocial = {
        align: 'end',
        preffix: '£',
        suffix: '',
        barPercentage: 0.8,
        labels: social.qi_media_value.labels,
        dataChart: social.qi_media_value.dataChart.map((num) => Math.round(num)),
        backgroundColor: '#992785',
        maxVal: Math.max(...social.qi_media_value.dataChart) * 1.2,
        width: 520
    }

    return <div style={{ width: '1259px', height: '390px', marginBottom: '40px' }}>

        <Tabs defaultActiveKey={'asdf'} tabPosition='left' onChange={callback} className='breakdown_by_source_tabs'>
            <TabPane tab={<div style={{ marginTop: '8px' }}><img src={currentTab === 'tv' ? tvIcon : tvIconInactive} /><div style={{ color: currentTab === 'tv' ? '#00A5DE' : 'black' }}>TV</div></div>} key="tv" tabBarStyle={tabsStyle}>
                <div className='audience_media_tabpane'>
                    {!!tv.audience.dataChart.length ? <div className='audience_bar_chart'>
                        <div >Audience</div>
                        <BarChart dataVal={audienceBar} />
                    </div> : <div style={{ marginTop: '120px' }}>No data available</div>}

                    {!!tv.qi_media_value.dataChart.length ? <div className='audience_bar_chart'>
                        <div >QI Media Value</div>
                        <BarChart dataVal={QIMediaValueTV} />
                    </div> : <div style={{ marginTop: '120px' }}>No data available</div>}

                </div>
            </TabPane>
            <TabPane tab={<div style={{ marginTop: '8px' }}><img style={{ width: '16px' }} src={currentTab === 'print' ? printIcon : printIconInactive} /><div style={{ color: currentTab === 'print' ? '#f39000' : 'black' }}>Print</div></div>} key="print" >
                <div className='audience_media_tabpane'>
                    {!!print.qi_media_value.dataChart.length ? <div className='audience_bar_chart'>
                        <div >QI Media Value</div>
                        <BarChart dataVal={QIMediaValuePrint} />
                    </div> : <div style={{ marginTop: '120px' }}>No data available</div>}
                </div>
            </TabPane>
            <TabPane tab={<div style={{ marginTop: '8px' }}><img style={{ width: '18px' }} src={currentTab === 'online' ? onlineIcon : onlineIconInactive} /><div style={{ color: currentTab === 'online' ? '#e72b78' : 'black' }}>Online</div></div>} key="online">
                <div className='audience_media_tabpane'>

                    {!!online.qi_media_value.dataChart.length ? <div className='audience_bar_chart'>
                        <div >QI Media Value</div>
                        <BarChart dataVal={QIMediaValueOnline} />
                    </div> : <div style={{ marginTop: '120px' }}>No data available</div>}

                </div>
            </TabPane>
            <TabPane tab={<div style={{ marginTop: '8px' }}><img style={{ width: '14px' }} src={currentTab === 'social' ? socialIcon : socialIconInactive} /><div style={{ color: currentTab === 'social' ? '#992785' : 'black' }}>Social</div></div>} key="social">
                <div className='audience_media_tabpane'>
                    {!!social.qi_media_value.dataChart.length ? <div className='audience_bar_chart'>
                        <div >QI Media Value </div>
                        <BarChart dataVal={QIMediaValueSocial} />
                    </div> : <div style={{ marginTop: '120px' }}>No data available</div>}
                </div>
            </TabPane>

        </Tabs>


    </div>
}

export default BreakdownBySource