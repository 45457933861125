import React from 'react'
import { Link } from "react-router-dom"
import './Footer.less'
import kindred_colors from 'assets/images/kindred.png';
import kindred_logo from 'assets/images/kindred-logo.png';

const Footer = () => {
    return (
        <div className='footer'>
            <img alt='kindred' src={kindred_colors} className='kindred_colors' />

            <div className='footer_rest'>
                <img alt='kindred' src={kindred_logo} />
                <Link to='/footer'>
                    <div className='footer_links'>
                        <div>Ts&Cs</div>
                        <div style={{ borderLeft: '2px solid black', height: '20px', marginTop: '5px' }}></div>
                        <div>Privacy Statement</div>
                        <div style={{ borderLeft: '2px solid black', height: '20px', marginTop: '5px' }}></div>
                        <div>Contact</div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Footer;