import store from '../../redux/store';
import { LOGOUT } from '../../redux/actions/types';
import { http } from './axios';
import log from "loglevel";
import tokenStorage from "../tokenStorage";

let isRefreshing = false;

export function authRequest(config) {
  const token = tokenStorage.getItem('authtoken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

export const authResponse = [
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        return http
          .post('/refresh')
          .then((res) => {
            if (res.status === 200) {

              let token = res?.data?.data?.token;

              if (token) {
                isRefreshing = false;
                tokenStorage.setItem('authtoken', token);
                return http(originalRequest);
              }

              throw new Error("Refresh failed!!");
            }
          })
          .catch((err) => {
            tokenStorage.removeItem('authtoken');
            store.dispatch({ type: LOGOUT });
            log.error(err);
          });
      } else {
        tokenStorage.removeItem('authtoken');
        store.dispatch({ type: LOGOUT });
      }
    }

    return Promise.reject(error);
  },
];
