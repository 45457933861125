import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import remote from "loglevel-plugin-remote";
import log from "loglevel";
import {http} from "./services/http";

let root = document.getElementById('root')
root.style.height = 'inherit'

const customJSON = log => ({
  msg: log.message,
  level: log.level.label,
  stacktrace: log.stacktrace
});

remote.apply(log, { format: customJSON, url: http.defaults.baseURL + '/logger' });
log.enableAll();

ReactDOM.render(<App />, root);
