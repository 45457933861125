import React, { useState, useEffect } from 'react'
import { Form, Icon, Input, notification, Button } from 'antd'
import { http } from 'services/http';
import qs from 'qs';
import { FilterSingleItem } from 'components/SponsorshipEvaluation/SponsorshipEvaluation';
import SponsorshipSelectionQuery from 'components/SponsorshipSelection/SponsorshipSelectionQuery'

import './SponsorshipSelection.less'

const { Search } = Input;

const SponsorshipSelection = ({ form }) => {

    const { getFieldDecorator, resetFields } = form;

    const filterData = [

        { name: 'Market', type: 'market' },
        { name: 'Sport', type: 'sport' },
        { name: 'Competition', type: 'property' },
        { name: 'Archetype', type: 'property_archetype' },
        { name: 'Sponsorship type', type: 'sponsorship_type' },
    ];

    const [arrowDirection, setArrowDirection] = useState({
        region: true,
        market: true,
        sport: true,
        property: true,
        property_archetype: true,
        sponsorship_type: true,
    });

    const [searchQuery, setSearchQuery] = useState({
        region: '',
        market: '',
        sport: '',
        property: '',
        property_archetype: '',
        sponsorship_type: '',
    });

    const [searchResults, setSearchResults] = useState({
        region: [],
        market: [],
        sport: [],
        property: [],
        property_archetype: [],
        sponsorship_type: [],
    });

    const [sponsorshipSearchData, setSponsorshipSearchData] = useState(null)
    const [queryData, setQueryData] = useState([]);
    const [currentQueryItemId, setCurrentQueryItemId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [searchDisabled, setSearchDisabled] = useState(true)
    const [animation, setAnimation] = useState(false)

    useEffect(() => {
        fetchSearchData()
    }, [searchQuery])

    const fetchSearchData = async () => {
        setSearchDisabled(true)
        let searchDataQuery = qs.stringify(searchQuery)
        try {
            let res = await http.get(`/sponsorship-selections/filters?${searchDataQuery}`)
            setSponsorshipSearchData(res.data.data)
            setSearchResults(res.data.data)
        } catch (err) {
            setSponsorshipSearchData(null)
            setSearchResults({
                region: [],
                market: [],
                sport: [],
                property: [],
                property_archetype: [],
                sponsorship_type: [],
            })

        } finally {
            setSearchDisabled(false)
            resetFields()
        }
    }

    const getQuery = async (queryD) => {
        setLoading(true)
        try {
            let res = await http.post('/sponsorship-selections', queryD)
            let queryItem = res.data?.data
            if (!!queryItem && !!queryItem.breakdown?.length && currentQueryItemId === null) {
                let query = [...queryData, queryItem]
                setQueryData(query)
                setCurrentQueryItemId(null)
                resetChart()
            }
        } catch (error) {
            const errors = error.response?.data?.errors
            if (errors) {
                Object.values(errors).flat().forEach((message) => {
                    notification.warning({ message })
                })
            }

        } finally {
            setLoading(false)
        }
    }

    const updateQuery = async () => {
        setLoading(true)
        try {
            let res = await http.post('/sponsorship-selections', searchQuery)
            let queryItem = res.data?.data
            if (!!queryItem && !!queryItem.breakdown?.length) {

                let query = [...queryData]
                query.splice(currentQueryItemId, 1, queryItem)
                setQueryData(query)
                setCurrentQueryItemId(null)
                resetChart()

            } else if (!queryItem.breakdown?.length) {
                notification.warning({ message: 'There is no data for this set of parameters' })
            }
        } catch (error) {
            const errors = error.response?.data?.errors
            if (errors) {
                Object.values(errors).flat().forEach((message) => {
                    notification.warning({ message })
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const setArrow = (type) => {
        setArrowDirection({
            ...arrowDirection,
            [type]: !arrowDirection[type],
        });
    };

    const removeQueryParam = (type) => {

        setSearchQuery({
            ...searchQuery,
            [type]: ''
        })
    }

    const searchDataQuery = (value, type) => {

        if (value.length > 0) {
            let res = sponsorshipSearchData[type].filter((item) =>
                item.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults({ ...searchResults, [type]: res });
        } else {
            setSearchResults({ ...searchResults, [type]: sponsorshipSearchData[type] });
        }
    }

    const setQueryParam = (item, type) => {
        setSearchQuery({
            ...searchQuery,
            [type]: item
        })
    }

    const resetChart = () => {
        setSearchQuery({
            region: '',
            market: '',
            sport: '',
            property: '',
            property_archetype: '',
            sponsorship_type: '',
        })

        setSearchResults({
            region: [],
            market: [],
            sport: [],
            property: [],
            property_archetype: [],
            sponsorship_type: [],
        })
        setCurrentQueryItemId(null)
        resetFields()
    }

    const createQuery = () => {
        getQuery(searchQuery)
    }

    //******************* */
    const editQuery = (id) => {
        let currentQuery = queryData[id].filters
        setSearchQuery(currentQuery)
        setCurrentQueryItemId(id)
    }

    const deleteQuery = (id) => {
        const query = [...queryData]
        query.splice(id, 1)
        setQueryData(query)
    }

    const SingleSearchField = (name, type) => {
        return (
            <div key={name}>
                <FilterSingleItem
                    name={name}
                    direction={arrowDirection[type]}
                    click={() => setArrow(type)}
                />
                {arrowDirection[type] && (
                    <div>
                        {searchQuery[type] && !!searchQuery[type] && (
                            <div className="sponsorship_evaulation_filter_results">

                                <div
                                    className="filter_item filter_item_selected"
                                    onClick={() => removeQueryParam(type)}>
                                    {searchQuery[type]}
                                    <Icon
                                        type="close"
                                        style={{ fontSize: '11px', marginLeft: '12px' }}
                                    />
                                </div>

                            </div>
                        )}

                        <Form.Item>
                            {getFieldDecorator(type)(

                                <Search
                                    placeholder="Start typing"
                                    onChange={(e) => searchDataQuery(e.target.value, type)}
                                    className="filter_search_input_field"
                                // disabled={searchDisabled || !!searchQuery[type]}
                                />

                            )}
                        </Form.Item>
                        <div className={`sponsorship_evaulation_filter_results search_results ${(searchDisabled || !!searchQuery[type]) && 'search_disabled_blocked'}`} >
                            {
                                searchResults[type] &&
                                searchResults[type].map((item) => {
                                    return (
                                        <div
                                            key={item}
                                            className="filter_item"
                                            onClick={() => setQueryParam(item, type)}>
                                            {item}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const SingleSearchFieldRegion = (name, type) => {
        return (
            <>
                <FilterSingleItem
                    name={name}
                    direction={arrowDirection[type]}
                    click={() => setArrow(type)}
                />
                {arrowDirection[type] && (
                    <div>
                        {searchQuery[type] && !!searchQuery[type] && (
                            <div className="sponsorship_evaulation_filter_results">

                                <div
                                    className="filter_item filter_item_selected"
                                    onClick={() => removeQueryParam(type)}>
                                    {searchQuery[type]}
                                    <Icon
                                        type="close"
                                        style={{ fontSize: '11px', marginLeft: '12px' }}
                                    />
                                </div>

                            </div>
                        )}

                        <div className="sponsorship_evaulation_filter_results search_results">
                            {(sponsorshipSearchData && !searchQuery.region) &&
                                [...sponsorshipSearchData.region].map((item) => {
                                    return (
                                        <div
                                            key={item}
                                            className="filter_item"
                                            onClick={() => setQueryParam(item, type)}>
                                            {item}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </>
        );
    };

    const fadeAnimation = () => {
        setAnimation(true)
        setTimeout(() => { setAnimation(false) }, 1500)
    }

    return (

        <div className="sponsorship_evaulation_layout">
            <div className="sponsorship_evaulation_filter">
                <div className={` ${animation && 'animation_fade'}`}></div>
                <div className='selection_filter'>
                    <div className="chart_set_up">Query Set Up</div>
                    <div className="reset_chart" onClick={resetChart}>
                        {' '}
                        <Icon type="reload" style={{ marginRight: '4px' }} />
                                Reset Query
                     </div>
                    {/* ======== Filters ========= */}
                    <Form>
                        {SingleSearchFieldRegion('Region', 'region')}
                        {filterData.map(({ name, type }) => {
                            return SingleSearchField(name, type);
                        })}
                    </Form>

                    {currentQueryItemId === null ?

                        <div className='selection_button'>
                            <Button loading={loading} onClick={createQuery}>
                                Create Query
                                </Button>
                        </div>
                        :
                        <div className='selection_button'>
                            <Button loading={loading} onClick={updateQuery}>
                                Update Query
                        </Button>
                        </div>
                    }

                </div>


            </div>
            <div className="sponsorship_evaulation_result">
                {!queryData.length ? <SelectionQueryUp fadeAnimation={fadeAnimation} /> : <div className='sponsorship_selection_wrap'>
                    <div className='sponsorship_selection_name'>Sponsorship Selection</div>
                    {queryData.map((data, key) => {
                        return <SponsorshipSelectionQuery key={key + 1} editQuery={editQuery} deleteQuery={deleteQuery} id={key} data={data} />
                    })}
                </div>}
            </div>
        </div>
    )
}

export default Form.create()(SponsorshipSelection)


const SelectionQueryUp = ({ fadeAnimation }) => {
    return <div className='selection_query_up'>
        <div className='selection_query_up_name'>Sponsorship Selection</div>
        <div className='selection_query_up_box'>
            <div className='start_set_up_box_text'>Add filters to the Query Set Up to be able to create and analyse your Query.</div>
            <div className='reset_all_filters' style={{ width: '290px' }} onClick={fadeAnimation}>Start Set Up</div>
        </div>

    </div>
}