import { combineReducers } from 'redux';
import auth from './auth';
import properties from './properties';
import property from './property';
import propertyMap from './propertyMap';

export default combineReducers({
  auth,
  properties,
  property,
  propertyMap,
});
