import React from 'react'
import HorizontalBarChart from '../../ChartComponents/HorizontalBarChart'
import './BreakdownByLocations.less'
import formatNumber from 'utils/formatNumber'


const BreakdownByLocations = ({ breakdown_by_location }) => {

    const qi_media_value = breakdown_by_location.breakdown;

    const backgroundColors = {
        tv: '#00a5de',
        print: '#f39000',
        online: '#e72b78',
        'social media': '#992785'
    }

    const maxScore = (() => {
        const score = Math.max(...qi_media_value.map(el => el.price))
        return Math.round(score * 1.1)
    })()

    const data = qi_media_value.map(el => {
        const { datasets, name, price, qi_score } = el;
        const newDataset = datasets.map(item => {
            item.backgroundColor = backgroundColors[item.label.toLowerCase()]
            return item
        })
        return {
            align: 'start',
            preffix: '$',
            suffix: '',
            barPercentage: 0.8,
            maxVal: maxScore,
            width: 520,
            datasets: newDataset,
            name,
            price,
            qi_score,
        }
    })


    return (
        <div className='breakdown_by_locations' style={{ display: 'flex', flexFlow: 'column', width: '1160px' }}>
            <SingleBar score={'QI Score'}>
                <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}><div>QI Media Value</div></div>
            </SingleBar>

            {data.map((el, i) => {
                const { name, price, qi_score, } = el
                return <SingleBar key={i} name={name} price={`£${formatNumber(Math.round(price))}`} score={Math.round(qi_score)} >
                    <HorizontalBarChart dataVal={el} />
                </SingleBar>
            })}
        </div>
    )
}

export default BreakdownByLocations

const SingleBar = (props) => {

    const { name, price, score } = props

    return <div className='single_chart_bar'>
        <div>{name}</div>
        <div>{props.children}</div>
        <div>{price}</div>
        <div>{score}</div>
    </div>
}