import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Moment from "react-moment";
import {http} from '../../../services/http';
import {getProperties} from "../../../redux/actions/properties";
import './PropertyManagement.less';
import {Icon, notification} from "antd";
import ReactFileReader from "react-file-reader";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

const PropertyManagement = ({properties, getProperties}) => {
    const [item, setItem] = useState(null);
    const [imgWidth, setImgWidth] = useState({});
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        getProperties();
    }, [getProperties]);

    useEffect(() => {
        if (item) {
            let itemUpdated = properties.find((property) =>(property.id === item.id));
            setItem(itemUpdated);
        }
    }, [properties]);

    const updateList = () => {
        getProperties();
    }

    const imageLoad = ({target: img}, property) => {
        imageResize(img, property, '70px', '42px', setImgWidth);
    }

    const deleteProperty = () => {
        http.delete(`/properties/${item.id}`).then(() => {
            closeDialog();
            getProperties();
        }).catch((err) => {
            let message = err.response?.data?.message;
            notification.warning({ message: message });
            closeDialog();
        });
    }

    const setDelete = (item) => {
        setItem(item);
        setShowDeleteDialog(true);
    }

    const closeDialog = () => {
        setShowDeleteDialog(false);
        setItem(null);
    }

    return (
        <>
            <div className="tab_layout">
                <div className={`properties ${(!item || (item && showDeleteDialog)) && 'extended'}`}>
                    <div className="properties_title">All properties</div>
                    <div className="properties_list">
                        <div className="list_header">
                            <div>Name</div>
                            <div>Market</div>
                            <div>Updated on</div>
                            <div></div>
                        </div>
                    </div>
                    {properties.length > 0 &&
                    properties.map((property, key) => {
                        const {
                            id,
                            market,
                            logo_url,
                            updated_at,
                            sponsorship_property
                        } = property;
                        return (
                            <div key={key} className={`item${(item && id === item.id) ? ' active' : ''}`}
                                 onClick={() => setItem(property)}>
                                <div>
                                    <div className="image-container">
                                        <img
                                            src={logo_url}
                                            onLoad={(event) => imageLoad(event, property)}
                                            style={{width: imgWidth[property.id]}}/>
                                    </div>
                                    <div className="property_name">
                                        {sponsorship_property}
                                    </div>

                                </div>
                                <div>
                                    {market}
                                </div>
                                <div>
                                    <Moment format="DD.MM.YYYY HH:mm">{updated_at}</Moment>

                                </div>
                                <div>
                                    <span className="icon-container">
                                        <Icon type={(item && id === item.id) ? 'edit' : 'right'} style={{fontSize: '10px'}}/>
                                    </span>
                                    <span className="icon-container">
                                        <Icon type="delete" style={{fontSize: '10px'}} onClick={() => setDelete(item)}/>
                                    </span>
                                </div>
                            </div>
                        );
                    })
                    }
                </div>
                {!showDeleteDialog && item &&
                    <EditProperty item={item} updateList={updateList}/>
                }
            </div>
            {item &&
                <ConfirmDialog
                    closeDialog={closeDialog}
                    itemName={item.sponsorship_property}
                    showDialog={showDeleteDialog}
                    modalTitle="Delete property"
                    handleDelete={deleteProperty}
                />
            }
        </>
    );
};
const mapStateToProps = (state) => ({
    properties: state.properties.properties,
    loading: state.properties.loading,
});

const EditProperty = ({item, updateList}) => {
    const [file, setFile] = useState(null);
    const [percentage, setPercentage] = useState(0);
    const [imgWidth, setImgWidth] = useState({});

    const getImageContent = (logoUrl) => {

        return logoUrl ? <img src={logoUrl} onLoad={imageLoad} style={{width: imgWidth[item.id]}}/> : 'No file yet.'
    }

    const imageLoad = ({target: img}) => {
        imageResize(img, item, '145px', '80px', setImgWidth);
    }

    const handleFiles = (file) => {
        setFile(file.fileList[0]);
    }

    const uploadFile = () => {
        let data = new FormData();
        data.append('logo_file', file);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                if (percent === 100) {
                    setPercentage('Processing...');
                } else {
                    setPercentage(percent + ' ' + '%')
                }
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        http.post(`/properties/${item.id}/logo-upload`, data, options).then(() => {
            updateList();
        }).catch((err) => {
            let errors = err.response?.data?.errors?.logo_file;
            let message = err.response?.data?.message;
            if (errors) {
                errors.forEach(error => {
                    notification.warning({ message: error });
                })
            } else if(message) {
                notification.warning({ message: message });
            }
        }).finally(() => {
            setPercentage(0);
        });
    }

    return (
        <div className="edit_property">
            <div className="title">{item.sponsorship_property}</div>
            <div className="logo_title">
                Property logo
            </div>
            <div className="details">
                <div className="logo_wrapper">
                    {getImageContent(item.logo_url)}
                </div>
                <div className="upload_wrapper">
                    <div className="instruction">
                        {item.logo_url ? 'Want to change property logo?' : 'Upload property logo'}
                    </div>
                    <div className="instruction_details">
                        {item.logo_url
                            ? 'Click “upload new logo” to change the current logo to a new file of your choice.'
                            : ''
                        }
                    </div>
                    <div>
                        <ReactFileReader
                            fileTypes={['.png', '.jpg', '.svg']}
                            base64={true}
                            multipleFiles={false}
                            handleFiles={handleFiles}
                        >
                            <div className='upload_document_wrapper'>
                                <div className='upload_document'><Icon type="upload" style={{marginRight: '10px'}}/>
                                    Upload new logo
                                </div>
                                <div>{file && file.name}</div>
                            </div>
                        </ReactFileReader>
                    </div>
                </div>
            </div>
            <div className='upload_document_wrapper'>
                <div className={`confirm_upload ${!file ? 'confirm_upload_disabled' : ''}`}
                     onClick={uploadFile}>Confirm upload
                </div>
                {!!percentage && <div>{percentage}</div>}
            </div>
        </div>

    );
}

const imageResize = (img, item, widthLandscape, widthPortrait, setter) => {

    if(Number(img.naturalWidth) >= Number(img.naturalHeight)) {
        setter(oldImages => ({...oldImages, [item.id]: widthLandscape}));
    } else {
        setter(oldImages => ({...oldImages, [item.id]: widthPortrait}));
    }
}
export default connect(mapStateToProps, {getProperties})(PropertyManagement);
