import React, { useState } from 'react'
import { Tabs } from 'antd';
import './AudienceMedia.less'
import BreakdownBySource from './BreakdownBySource'
import BreakdownByLocations from './BreakdownByLocations'
import formatNumber from 'utils/formatNumber'
import media from 'assets/images/media.png'
import monitor from 'assets/images/monitor.png'

const { TabPane } = Tabs;

const AudienceMedia = ({ audience_media_value }) => {
    const { audience_tv, source_feed_duration_tv, breakdown_by_source, breakdown_by_location } = audience_media_value

    const [tabRoute, setTabRoute] = useState('')
    const [bar1, bar2] = breakdown_by_location
    const callback = (route) => {
        setTabRoute(route)
    }

    const callbackLocation = (route) => {

    }

    const tabsStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const tabContent = (name, price) => {
        let finalPrice

        if (price > 1000 && price < 1000000) {
            finalPrice = (price / 1000).toFixed(1) + "k"
        } else if (price > 1000000) {

            finalPrice = (price / 1000000).toFixed(1) + "m"
        } else {
            finalPrice = Math.round(price)
        }

        return <div className='location_tab_content'>
            <div>{name}</div>
            <div>£{finalPrice}</div>
        </div>
    }

    return (
        <div className='audience_media shadow'>
            <div className='audience_media_title'>Audience & Media Value</div>
            <div className='audience_source_feed_duration'>
                <div>
                    <div>Audience (TV)</div>
                    <div> <img src={media} /> {formatNumber(Math.round(audience_tv))}</div>
                </div>
                <div>
                    <div> Source Feed Duration (TV)</div>
                    <div><img src={monitor} /> {source_feed_duration_tv}</div>
                </div>
                <div></div>
            </div>
            <div className='audience_media_tabs' style={{ position: 'relative' }}>

                <Tabs defaultActiveKey={'asdf'} onChange={callback} >
                    <TabPane tab="Breakdown By Source" key="breakdown_by_source" >
                        <BreakdownBySource breakdown_by_source={breakdown_by_source} />
                    </TabPane>
                    <TabPane tab="Breakdown By Location" key="breakdown_by_location" >
                        <Tabs defaultActiveKey={'asdf'} tabPosition='left' onChange={callbackLocation} className='breakdown_location'>
                            {!!bar1 && <TabPane tab={tabContent(bar1?.brand?.name, bar1?.brand?.price)} key="red" tabBarStyle={tabsStyle}>
                                <BreakdownByLocations breakdown_by_location={breakdown_by_location[0]} style={{ width: '1120px' }} />
                            </TabPane>}
                            {!!bar2 && <TabPane tab={tabContent(bar2?.brand?.name, bar2?.brand?.price)} key="unibet" tabBarStyle={tabsStyle}>
                                <BreakdownByLocations breakdown_by_location={breakdown_by_location[1]} />
                            </TabPane>}
                        </Tabs>
                    </TabPane>
                </Tabs>
                {tabRoute == 'breakdown_by_location' && <div style={{ position: 'absolute', right: '40px' }}><BreakdownByLocationsKeys /></div>}
            </div>

        </div>
    )
}

export default AudienceMedia

export const BreakdownByLocationsKeys = () => {
    return (

        <div className='breakdown_by_location_key_description' >
            <div className='breakdown_key_name'>Key:</div>

            <div className='single_breakdown_wrap'>
                <div className='breakdown_rectangle social_name'></div>
                <div className='breakdown_name'>Social</div>
            </div>

            <div className='single_breakdown_wrap'>
                <div className='breakdown_rectangle online_name'></div>
                <div className='breakdown_name'>Online</div>
            </div>

            <div className='single_breakdown_wrap'>
                <div className='breakdown_rectangle print_name'></div>
                <div className='breakdown_name'>Print</div>
            </div>

            <div className='single_breakdown_wrap'>
                <div className='breakdown_rectangle tv_name'></div>
                <div className='breakdown_name'>TV</div>
            </div>

        </div>
    )
}