import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './routes';
import {Provider} from 'react-redux';
import store from './redux/store';

const App = () => {

  const [currentWidth, setCurrentWidth] = React.useState(1.0)

  React.useEffect(() => {
    getCurrentPercentage()
  }, [])

  window.onresize = () => {
    getCurrentPercentage()
  }

  const getCurrentPercentage = () => {
    let screenWidth = window.innerWidth
    let percentage = screenWidth * 100 / 1920
    let ceilNum = Math.ceil(percentage)

    if (ceilNum < 100 && ceilNum > 50) {
      setCurrentWidth(`0.${ceilNum}`)
    } else if (ceilNum <= 50) {
      setCurrentWidth(`0.5`)
    } else {
      setCurrentWidth(1.0)
    }
  }

  const scaleTransform = {
    transform: `scale(${currentWidth})`,
    transformOrigin: '0 0'
  }

  return (
    <div style={scaleTransform}>
      <Provider store={store}>
        <Router>
          <Routes/>
        </Router>
      </Provider>
    </div>
  )
};

export default App;
