import React from 'react'
import BarChartStrategicPerformance from '../../../ChartComponents/BarChartStrategicPerformance'
import chartDataFormatter from '../../../../utils/chartDataFormatter'

const LocalAssetReach = ({ dataVal }) => {

    const { columnTitles } = dataVal;
    const { items: [first, second] } = dataVal;

    const chartValues = [...first.columnValues, ...second.columnValues]
    const maximumValue = Math.max(...chartValues) * 1.3

    const awareness = chartDataFormatter(columnTitles, first.columnValues, first.base_met, first.name, first.value, maximumValue)
    const interest = chartDataFormatter(columnTitles, second.columnValues, second.base_met, second.name, second.value, maximumValue)

    return (
        <div className='tab_container' style={{ justifyContent: 'space-between', padding: '0px 250px 0px 250px' }}>
            <BarChartStrategicPerformance dataVal={awareness} />
            <BarChartStrategicPerformance dataVal={interest} />
        </div>
    )
}

export default LocalAssetReach
