import React from 'react'
import BarChartStrategicPerformance from '../../../ChartComponents/BarChartStrategicPerformance'
import chartDataFormatter from '../../../../utils/chartDataFormatter'

import './BrandImpact.less'


const BrandImpact = ({ dataVal }) => {

    const {columnTitles, items: [first, second, third] } = dataVal;

    const chartValues = [...first.columnValues, ...second.columnValues, ...third.columnValues]
    const maximumValue = Math.max(...chartValues) * 1.3

    const awareness = chartDataFormatter(columnTitles, first.columnValues, first.base_met, first.name, first.value, maximumValue)
    const interest = chartDataFormatter(columnTitles, second.columnValues, second.base_met, second.name, second.value, maximumValue)
    const interestSecond = chartDataFormatter(columnTitles, third.columnValues, third.base_met, third.name, third.value, maximumValue)


    return (
        <div className='tab_container' style={{ justifyContent: 'space-between', padding: '0px 90px 0px 90px' }}>
            <BarChartStrategicPerformance dataVal={awareness} />
            <BarChartStrategicPerformance dataVal={interest} />
            <BarChartStrategicPerformance dataVal={interestSecond} />
        </div>
    )
}

export default BrandImpact
