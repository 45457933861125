import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DoughnutChart = (tangible, sales) => {
    const onePercent = (tangible + sales) / 100;
    const tangiblePercentage = Math.round(tangible / onePercent);
    const salesPercentage = Math.round(sales / onePercent);

    const data = {
        labels: [
            'Tangible Value',
            'Brand Funnel Impact Value'
        ],
        datasets: [{
            data: [tangiblePercentage, salesPercentage],
            backgroundColor: [
                'rgb(0,165,222)',
                '#992785',

            ],
            hoverBackgroundColor: [
                'rgb(0,165,222)',
                '#992785',
            ]
        }]
    }

    return (
        <div>
            <Doughnut
                data={data}
                width={320}
                height={320}
                options={{
                    maintainAspectRatio: false,
                    cutoutPercentage: 70,
                    scaleShowLabels: false,
                    legend: {
                        display: false
                    },
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            align: 'start',
                            color: '#ffffff',
                            formatter: function (value) {
                                return value + '%';
                            },
                            font: {
                                color: 'rgb(255,255,255)',
                                weight: 'bold',
                                family: 'Factoria',
                                size: 16
                            }
                        }
                    }
                }}
            />
        </div>
    )
}

export default DoughnutChart