import React, { useState } from 'react'
import { Icon, Tabs } from 'antd'
import { selectionColor, selectionTabColor } from 'utils/detectColor'
import HalfDoughnutChart from 'components/ChartComponents/HalfDoughnutChart'

import './SponsorshipSelection.less'

const { TabPane } = Tabs;

const SponsorshipSelectionQuery = ({ id, editQuery, deleteQuery, data }) => {

    const { filters, breakdown, total_score } = data

    return (!!breakdown.length &&
        <div className='selection_query'>
            <div className='query_top_element'>
                <div className='query_num'>Query {id + 1}</div>
                <div className='query_button_wrap'>
                    <EditDeleteButton type='edit' text='Edit Query' click={editQuery} id={id} />
                    <EditDeleteButton type='delete' text='Delete Query' click={deleteQuery} id={id} />
                </div>
            </div>

            <QueryProperties filters={filters} />

            <div className='score_breakdown_wrap'>
                <div className='overall_score'>
                    <div className='overall_score_name'>Overall Score</div>
                    {HalfDoughnutChart(total_score, 40, selectionColor(total_score))}
                </div>
                <div>
                    <div className='score_breakdown'>
                        <div className='score_breakdown_name'>Score Breakdown</div>
                        <ScoreBreakdownTabs data={breakdown} />
                    </div>

                    <SourceKeyExplanarion />

                </div>
            </div>

        </div>
    )
}

export default SponsorshipSelectionQuery


const EditDeleteButton = ({ type, text, click, id }) => {
    return <div className='query_button' onClick={() => click(id)}>
        <Icon type={type} />
        {text}
    </div>
}

const QueryProperties = ({ filters }) => {

    const filterNames = {
        "region": "Region",
        "market": "Market",
        "sport": "Sport",
        "property": "Competition",
        "property_archetype": "Property Archetype",
        "sponsorship_type": "Sponsorship Type"
    }

    return <div className='query_properties'>
        {Object.keys(filters).map((item) => {
            return <div key={filterNames[item]} className='single_query_prop'>
                <div>{filterNames[item]}</div>
                <div>{filters[item]}</div>
            </div>
        })}
    </div>
}

const ScoreBreakdownTabs = ({ data }) => {

    const [currentColor, setCurrentColor] = useState(data[0].avg_kpi_score)

    const callback = (num) => {
        setCurrentColor(JSON.parse(num).avg_kpi_score)
    }

    const tab = (name, num) => {
        return <div className='selection_tab_style'>
            <div>{name}</div>
            <div style={{ color: `${selectionColor(num)}` }}>{Number(num).toFixed(1)}</div>
        </div>
    }

    const tabWindowItem = (breakdown) => {

        const { individual_score, kpi_metric, real_score } = breakdown

        return <div key={kpi_metric} className='tab_win_item'>
            <div>
                <div>{kpi_metric}</div>
                {individual_score > 0 && <div style={{ backgroundColor: selectionColor(individual_score) }}>{Number(individual_score).toFixed(1)}</div>}
            </div>
            <div>{real_score}</div>

        </div>

    }

    return <div className={`source_breakdown_tabs arrow_sign_sponsorship ${selectionTabColor(currentColor)}`}>
        <Tabs defaultActiveKey={'as'} onChange={callback}>

            {data.map((item) => {

                const { kpi_type, avg_kpi_score, breakdown } = item

                return <TabPane
                    key={kpi_type}
                    tab={tab(kpi_type, avg_kpi_score)}
                    key={JSON.stringify({ avg_kpi_score, kpi_type })}
                >

                    <div className='selection_tab_window'>

                        {breakdown.map((item) => {
                            return tabWindowItem(item)
                        })}

                    </div>
                </TabPane>
            })}

        </Tabs>
    </div>
}

const SourceKeyExplanarion = () => {
    return <div className='score_key_explanation'>
        <div>Key:</div>
        <div className='selection_description'>
            <div style={{ backgroundColor: selectionColor(3) }}>{'< 4'}</div>
            <div>Weak Performance</div>
        </div>
        <div className='selection_description'>
            <div style={{ backgroundColor: selectionColor(5) }}>{'4 - 6'}</div>
            <div>Average Performance</div>
        </div>
        <div className='selection_description'>
            <div style={{ backgroundColor: selectionColor(7) }}>{'6 - 8'}</div>
            <div>Strong Performance</div>
        </div>
        <div className='selection_description'>
            <div style={{ backgroundColor: selectionColor(9) }}>{'> 8'}</div>
            <div>Very Strong Performance</div>
        </div>
    </div>
}