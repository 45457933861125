import { LOGIN_SUCCESS, LOGOUT, LOGIN_FAIL, LOAD_USER } from '../actions/types';
import { property } from 'lodash';
import tokenStorage from "../../services/tokenStorage";

const initialState = {
  isAuthenticated: !!tokenStorage.getItem('authtoken'),
  user: null,
  sponsorship_evaluation: {},
  adminPermission: {},
  headerTabs: {},
  methodology: {},
  propertyPermisions:{}
};

export default function (state = initialState, action) {

  const { payload, type } = action

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      };

    case LOAD_USER:
      const { website_permissions, upload_permissions, user_permissions, role } = payload
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        sponsorship_evaluation: sponsorship_evaluation(website_permissions, role),
        adminPermission: adminPermission(upload_permissions, user_permissions, role),
        headerTabs: headerTabs(payload, role),
        methodology: methodology(website_permissions, role),
        propertyPermisions: propertyPermisions(website_permissions, role)
      };

    case LOGIN_FAIL:
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
}


const sponsorship_evaluation = (permissions, role) => {
  const e = new Set(permissions)
  if (role === 'super admin' || role === 'admin') {
    return {
      property_map: true,
      property_analysis: true,
      asset_analysis: true
    }
  } else if (role === 'client') {
    return {
      property_map: e.has('sponsorship_evaluation.property_map'),
      property_analysis: e.has('sponsorship_evaluation.property_analysis'),
      asset_analysis: e.has('sponsorship_evaluation.asset_analysis')
    }
  } else {
    return {
      property_map: false,
      property_analysis: false,
      asset_analysis: false
    }

  }
}

const adminPermission = (upload_permissions, user_permissions, role) => {

  const u = new Set(upload_permissions)
  const edit = new Set(user_permissions)

  if (role === 'super admin') {
    return {
      adminUpload: true,
      property_information: true,
      tangible_performance: true,
      strategic_performance: true,
      property_performance: true,
      sponsorship_selections: true,
      adminManagement: true,
      propertyManagement: true,
      uploadDelete: true,
    }
  } else if (role === 'admin') {
    return {
      adminUpload: u.has('data_upload.property_information') || u.has('data_upload.tangible_performance') || u.has('data_upload.strategic_performance') || u.has('data_upload.property_performance') || u.has('data_upload.sponsorship_selections'),
      property_information: u.has('data_upload.property_information'),
      tangible_performance: u.has('data_upload.tangible_performance'),
      strategic_performance: u.has('data_upload.strategic_performance'),
      property_performance: u.has('data_upload.property_performance'),
      sponsorship_selections: u.has('data_upload.sponsorship_selections'),
      adminManagement: edit.has('create_edit_user.create_edit_user'),
      propertyManagement: false,
      uploadDelete: false,
    }
  }
}

const methodology = (website_permissions, role) => {
  const e = new Set(website_permissions)

  if (role === 'super admin' || role === 'admin') {
    return {
      kindred_scope: true,
      qi_media_evaluation_methodology: true,
      rosi_model_methodology: true
    }
  } else if (role === 'client') {
    return {
      kindred_scope: e.has('methodology.kindred_scope'),
      qi_media_evaluation_methodology: e.has('methodology.qi_media_evaluation_methodology'),
      rosi_model_methodology: e.has('methodology.rosi_model_methodology')
    }
  }
}

const headerTabs = (payload, role) => {

  const { website_permissions, upload_permissions, user_permissions } = payload
  const e = new Set(website_permissions)
  const u = new Set(upload_permissions)
  const edit = new Set(user_permissions)
  if (role === 'super admin') {
    return {
      sponsorship_evaluation: true,
      sponsorship_selection: true,
      admin: true,
      methodology: true
    }
  } else if (role === 'admin') {
    return {
      sponsorship_evaluation: true,
      sponsorship_selection: true,
      admin: u.has('data_upload.property_information') || u.has('data_upload.tangible_performance') || u.has('data_upload.strategic_performance') || u.has('data_upload.property_performance') || u.has('data_upload.sponsorship_selections') || edit.has('create_edit_user.create_edit_user'),
      methodology: true
    }
  } else if (role === 'client') {
    return {
      sponsorship_evaluation: e.has('sponsorship_evaluation.property_map') || e.has('sponsorship_evaluation.property_analysis') || e.has('sponsorship_evaluation.asset_analysis'),
      sponsorship_selection: e.has('sponsorship_selection.sponsorship_selection'),
      admin: false,
      methodology: e.has('methodology.kindred_scope') || e.has('methodology.qi_media_evaluation_methodology') || e.has('methodology.rosi_model_methodology')
    }
  } else {
    return {
      sponsorship_evaluation: false,
      sponsorship_selection: false,
      admin: false,
      methodology: false
    }
  }

}

const propertyPermisions = (website_permissions, role) => {

  const e = new Set(website_permissions)

  if (role === 'super admin' || role === 'admin') {
    return {
      rights_and_activations: true
    }
  } else if (role === 'client') {
    return {
      rights_and_activations: e.has('property_profile.rights_and_activations')
    }
  }
}
