import { http } from 'services/http';
import { PROPERTY, UPDATE_LOADING_PROPERTY, UPDATE_SEASON } from './types';

export const getProperty = (id) => async (dispatch) => {
  try {
    const res = await http.get(`/properties/${id}`);
    dispatch({
      type: PROPERTY,
      payload: res.data.data,
    });
  } catch { }
};

export const updateLoadingProperty = () => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING_PROPERTY
  });
};

export const updateSeason = (id) => (dispatch) => {
  dispatch({
    type: UPDATE_SEASON,
    id
  })
}