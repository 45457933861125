import React from 'react'
import { Tabs, Icon } from 'antd';
import HalfDoughnutAcquisitionChart from '../../../ChartComponents/HalfDoughnutAcquisitionChart'
import LineChartAcquisition from '../../../ChartComponents/LineChartAcquisition'

import './Acquisition.less'

const { TabPane } = Tabs;


const Acquisition = () => {

    const callback = (route) => {
    }
    //Overview data
    const registrations = {
        backgroundColor: '#eb462b', labelName: 'Registrations', dataProp: [90, 100]
    }

    const newDepositingPlayers = {
        backgroundColor: '#eb462b', labelName: 'New depositing players', dataProp: [79, 100]
    }

    const conversion = {
        backgroundColor: '#04ad63', labelName: 'Conversion (%)', dataProp: [120, 140]
    }

    const leadGeneration = {
        backgroundColor: '#eb462b', labelName: 'Lead generation', dataProp: [120, 140]
    }

    //Details data

    const registrationsLine = { datasetsVal: [100, 50, 30, 150, 20, 80, 180, 120, 90, 60, 10, 50], maxVal: 200, titleText: 'Registrations' }
    const newDepositingPlayersLine = { datasetsVal: [10, 150, 15, 190, 20, 80, 180, 150, 16, 50, 100, 4], maxVal: 200, titleText:'New Depositing Players' }
    const conversionLine = { datasetsVal: [30, 80, 130, 50, 200, 60, 180, 110, 90, 160, 10, 150], maxVal: 200, titleText:'Conversion (%)' }
    const leadGenerationLine = { datasetsVal: [100, 50, 30, 150, 20, 80, 180, 120, 90, 60, 10, 50], maxVal: 200, titleText:'Lead Generation' }


    return <div style={{ width: '1259px', 'margin-bottom': '40px' }}>

        <Tabs defaultActiveKey={'asdf'} tabPosition='left' onChange={callback} className='breakdown_by_source_tabs'>
            <TabPane tab={<div style={{ marginTop: '8px' }}><Icon type="pie-chart" style={{ margin: '0px' }} /><div>Overview</div></div>} key="tv" >
                <div className='acquisition_tabpane'>
                    <HalfDoughnutAcquisitionChart dataVal={registrations} />
                    <HalfDoughnutAcquisitionChart dataVal={newDepositingPlayers} />
                    <HalfDoughnutAcquisitionChart dataVal={conversion} />
                    <HalfDoughnutAcquisitionChart dataVal={leadGeneration} />
                </div>
            </TabPane>
            <TabPane tab={<div style={{ marginTop: '8px' }}><Icon type="line-chart" style={{ margin: '0px' }} /><div>Details</div></div>} key="print" >
                <div className='acquisition_tabpane'>
                    <div style={{display:'flex',flexFlow:'column', border:'solid 0.5px #bdbdbd', margin:'20px'}}>

                        <div style={{ width: '1000px', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                            <LineChartAcquisition dataVal={registrationsLine} />
                            <LineChartAcquisition dataVal={newDepositingPlayersLine} />
                        </div>
                        
                        <div style={{ width: '1000px', display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                            <LineChartAcquisition dataVal={conversionLine} />
                            <LineChartAcquisition dataVal={leadGenerationLine} />
                        </div>
                    </div>
                </div>
            </TabPane>
        </Tabs>
    </div>
}

export default Acquisition