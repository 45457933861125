import React, { memo } from "react";
import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography
} from "react-simple-maps";
import vanDerGrinten from './d3/vanDerGrinten'

const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";


const mapStyle = {
    default: {
        fill: "#D6D6DA",
        outline: "none"
    },
    hover: {
        fill: "#F53",
        outline: "none"
    },
    pressed: {
        fill: "#E42",
        outline: "none"
    }
}
const mapStyleSelected = {
    default: {
        fill: "#992785",
        outline: "none"
    },
    hover: {
        fill: "#F53",
        outline: "none"
    },
    pressed: {
        fill: "#E42",
        outline: "none"
    }
}


const MapChart = ({ setTooltipContent, countries }) => {
    return (
        <>
            <ComposableMap data-tip="" projectionConfig={{ scale: 400 }}
                projection={vanDerGrinten()}
                // width={600}
                // height={300}
                viewBox='270 50 450 400'
            >
                <ZoomableGroup>
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map(geo => {
                            
                                const finalStyle = countries.has(geo.properties.NAME) ? mapStyleSelected : mapStyle;
                   
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        // onClick={() => {
                                        //     const { NAME, POP_EST } = geo.properties;
                                        //     // const { NAME, POP_EST } = { NAME: 'Venezuela', POP_EST: 31304016 }
                                        //     // console.log{NAME:'Venezuela', POP_EST:31304016}
                                        //     // Venezuela 31304016
                                        //     // setTooltipContent(`${NAME} — ${rounded(POP_EST)}`);
                                        // }}
                                        // onMouseLeave={() => {
                                        //     // setTooltipContent("");
                                        // }}
                                        // projection={projection}
                                        style={finalStyle}
                                    />
                                )
                            })
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
        </>
    );
};

export default memo(MapChart);