import React from 'react'
import { Line } from 'react-chartjs-2'


const LineChart = ({ dataVal }) => {
    const { labels, aware, unaware } = dataVal
    const maxVal = Math.max(...aware, ...unaware)
    const data = {
        labels,
        datasets: [
            {
                label: 'Rainfall',
                fill: false,
                lineTension: 0,
                backgroundColor: '#00a5de',
                borderColor: '#00a5de',
                borderWidth: 1,
                data: aware
            },
            {
                label: 'Rainfal',
                fill: false,
                lineTension: 0,
                backgroundColor: '#4c4c4c',
                borderColor: '#4c4c4c',
                borderWidth: 1,
                data: unaware
            }
        ]
    }

    const options = {
        title: {
            display: false,
            text: 'Average Rainfall per month',
            fontSize: 20
        },
        legend: {
            display: false,
            position: 'right'
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'start',
                color: 'rgba(0, 0, 0, 0.5)',
                formatter: function (value) {
                    return value + '%';
                },
                font: {
                    color: '#000000',
                    size: 12
                }
            }
        },
        scales: {
            yAxes: [{

                ticks: {
                    max: maxVal,
                    beginAtZero: true,
                }
            }],
            xAxes: [{
                barPercentage: 1.0,
                gridLines: {
                    display: false,
                }
            }]
        },
    }

    return (
        <div>
            <Line
                data={data}
                options={options}
                width={1200}
                height={350}
            />
        </div>
    )
}


export default LineChart