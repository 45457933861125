import { http } from 'services/http';
import { notification } from 'antd';
import log from 'loglevel';

import { LOGIN_SUCCESS, LOGOUT, LOGIN_FAIL, LOAD_USER } from './types';
import tokenStorage from "../../services/tokenStorage";

export const loadUser = () => async (dispatch) => {
  const userResponse = await http.post('/me');

  dispatch({
    type: LOAD_USER,
    payload: userResponse?.data?.data,
  });
};

export const login = ({ email, password }) => async (dispatch) => {
  const body = {
    email,
    password,
  };

  try {
    const res = await http.post('/login', body);
    // dispatch({
    //   type: LOGIN_SUCCESS,
    // });
    tokenStorage.setItem('authtoken', res.data.data.token);

    log.info('Logged IN' + res.data.data.token);


    // notification.success({
    //     message: "Logged In",
    //     description: "You have successfully logged in"
    // })

    dispatch(loadUser());
  } catch (error) {
    console.log(error);
    dispatch({
      type: LOGIN_FAIL,
    });
    notification.warning({
      message: 'Wrong email or password',
      description: 'Please type correct email and password',
    });

    log.error(error);
  }
};

export const logout = () => (dispatch) => {
  tokenStorage.removeItem('authtoken');
  dispatch({ type: LOGOUT });
};
