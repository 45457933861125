import React from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { loadUser } from '../redux/actions/auth';

import Login from 'components/auth/Login';
import Layout from 'components/Layout/Layout';
import ResetPasswordEmail from 'components/auth/ResetPasswordEmail/ResetPasswordEmail';
// import ResetPassword from 'components/auth/ResetPassword/ResetPassword';
// import CreatePassword from 'components/auth/CreatePassword/CreatePassword';
// import VerifyEmail from 'components/auth/VerifyEmail/VerifyEmail';
import KindredProperties from 'components/KindredProperties/KindredProperties';
import KindredProperty from 'components/KindredProperty/KindredProperty';
import SponsorshipEvaluation from 'components/SponsorshipEvaluation/SponsorshipEvaluation';
import SposnsorshipSeletion from 'components/SponsorshipSelection/SponsorshipSelection'
import Methodology from 'components/Methodology/Methodology'
import Admin from 'components/Admin/Admin'
import FooterPage from 'components/FooterPage/FooterPage'
import UserProfile from "components/UserProfile/UserProfile";
import tokenStorage from "../services/tokenStorage";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/reset-password-email" component={ResetPasswordEmail} />
      {/*<Route exact path="/reset-password" component={ResetPassword} />*/}
      {/*<Route exact path="/create-password" component={CreatePassword} />*/}
      {/*<Route exact path="/verify-email" component={VerifyEmail} />*/}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

const PrivateRoutes = ({ sponsorship_evaluation, sponsorship_selection, admin, methodology }) => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/kindred-properties" component={KindredProperties} />
        <Route exact path="/user-profile" component={UserProfile} />
        <Route
          exact
          path="/kindred-property/:property_num"
          component={KindredProperty}
        />
        {sponsorship_selection && <Route
          exact
          path="/sponsorship-selection"
          component={SposnsorshipSeletion}
        />}
        {sponsorship_evaluation && <Route
          exact
          path="/sponsorship-evaluation/:sub_cat?"
          component={SponsorshipEvaluation}
        />}
        {methodology && <Route
          exact
          path="/methodology"
          component={Methodology}
        />}
        {admin && <Route
          exact
          path="/admin/:admin_cat?"
          component={Admin}
        />}
        <Route exact path="/footer" component={FooterPage} />
        <Route render={() => <Redirect to="/kindred-properties" />} />
      </Switch>
    </Layout>
  );
};

const Routes = ({ sponsorship_evaluation, sponsorship_selection, admin, methodology }) => {
  const token = tokenStorage.getItem('authtoken');
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (!auth.user && token) {
      dispatch(loadUser());
    }
  }, [dispatch, auth.isAuthenticated, auth.user, token]);

  if (token && !auth.user) return null;
  return !!auth.user && auth.isAuthenticated ? (
    <PrivateRoutes sponsorship_evaluation={sponsorship_evaluation}
      sponsorship_selection={sponsorship_selection} admin={admin} methodology={methodology} />
  ) : (
      <PublicRoutes />
    );
};
const mapStateToProps = (state) => ({
  sponsorship_evaluation: state.auth?.headerTabs?.sponsorship_evaluation,
  sponsorship_selection: state.auth?.headerTabs?.sponsorship_selection,
  admin: state.auth?.headerTabs?.admin,
  methodology: state.auth?.headerTabs?.methodology
});

export default connect(mapStateToProps)(Routes);
