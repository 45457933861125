import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { http } from 'services/http';
import Moment from 'react-moment';
import { getProperties } from '../../redux/actions/properties';
import arrow from 'assets/images/arrow.png';
import { v4 as uuid } from 'uuid';

import './KindredProperties.less';
import log from "loglevel";

const KindredProperties = ({ loading, properties, getProperties }) => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('latest_rosi_score');
  const [favoritesId, setFavoritesId] = useState(null);
  const [favorites, setFavorites] = useState([]);


  useEffect(() => {
    const fetchFavorties = async () => {
      try {
        const res = await http.get('/favorites');
        setFavorites(res.data.data);
      } catch (err) {
        console.log(err);
        log.error(err)
      }
    };

    fetchFavorties();
  }, [favoritesId]);


  useEffect(() => {
    getProperties({ order: sortField, direction: sortDirection });
  }, [sortField, sortDirection, getProperties]);

  const sortOptions = [
    {
      value: 'latest_rosi_score',
      label: 'Rosi Score',
    },
    {
      value: 'id',
      label: 'Id',
    },
    {
      value: 'region',
      label: 'Region',
    },
    {
      value: 'type',
      label: 'Type',
    },
    {
      value: 'sport',
      label: 'Sport',
    },
    {
      value: 'updated_at',
      label: 'Updated',
    }

  ];

  const handlePropertiesSort = (e) => {
    setSortField(e.target.value);
  };

  const handleSortDirection = (e) => {
    setSortDirection(e.target.value);
  };


  return (
    <div className="kindred_properties">
      <div className="kindred_properties_name">Kindred Properties</div>
      {!!favorites.length && <><div className="my_favourites">My favourites</div>
        <div style={{ display: 'flex', marginTop: '22px', overflowX: 'auto' }}>
          {favorites.map((favorite) => (
            <FavouritesBox
              reFetchFavorites={() => setFavoritesId(uuid())}
              favorite={favorite}
            />
          ))}
        </div></>}

      <div className="all_properties">
        All properties{' '}
        <div style={{ display: 'flex', color: 'black', fontFamily: 'Factoria' }}>
          Sort By:
          <select
            onChange={handlePropertiesSort}
            style={{ marginLeft: '10px' }}
            placeholder="Sort">
            {sortOptions.map((o) => (
              <option key={o.value} value={o.value}>{o.label}</option>
            ))}
          </select>
          <select
            onChange={handleSortDirection}
            style={{ marginLeft: '10px' }}
            placeholder="Sort">
            <option value="desc">Desc</option>
            <option value="asc">Asc</option>
          </select>
        </div>
      </div>
      <div style={{ marginTop: '21px' }}>
        {properties.map((property) => AllPropertiesSingleItem(property))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  properties: state.properties.properties,
  loading: state.properties.loading,
});

export default connect(mapStateToProps, { getProperties })(KindredProperties);

const FavouritesBox = ({ favorite, reFetchFavorites }) => {
  const removeFavorite = async () => {
    try {
      await http.delete(`/favorites/${favorite.id}`);
      reFetchFavorites();
    } catch (err) {
      console.log(err);
      log.error(err)
    }
  };
  return (
    <div className="favourites_box">
      <div className="star_icon">
        <Icon
          onClick={removeFavorite}
          type="star"
          theme="filled"
          style={{ color: '#e72b78' }}
        />
      </div>
      <Link
        style={{ textAlign: 'center' }}
        to={`/kindred-property/${favorite.id}`}>
        <img alt='logo'
          src={favorite.logo_url}
          style={{ height: '30px' }}
        />
        <div className="box_club_name">{favorite.sponsorship_property}</div>
        <div className="box_club_num">{favorite.latest_rosi_score}</div>
      </Link>
    </div>
  );
};

const AllPropertiesSingleItem = (property) => {
  const {
    id,
    market,
    region,
    logo_url,
    sport,
    type,
    sponsorship_property,
    updated_at,
    latest_rosi_score: rosi_score

  } = property;

  let history = useHistory();
  const callback = () => {
    history.push(`/kindred-property/${id}`);
  };
  return (
    <div className="all_properties_single_item" key={id} onClick={callback}>
      <div className="single_item_club_name">
        <div className='single_item_image_container'> <ImageSize logo_url={logo_url} /></div>
        <div className='single_item_club_name_style'>{sponsorship_property}</div>
      </div>

      <div className="single_item_region items_positioning">
        <div className="single_item_key_style">Region</div>
        <div className="single_item_value_style">{region}</div>
      </div>

      <div className="single_item_market items_positioning">
        <div className="single_item_key_style">Market</div>
        <div className="single_item_value_style">{market}</div>
      </div>

      <div className="single_item_type items_positioning">
        <div className="single_item_key_style">Type</div>
        <div className="single_item_value_style">{type}</div>
      </div>

      <div className="single_item_sport items_positioning">
        <div className="single_item_key_style">Sport</div>
        <div className="single_item_value_style">{sport}</div>
      </div>

      <div className="single_item_last_update items_positioning">
        <div className="single_item_key_style">Last Update</div>
        <div className="single_item_value_style">
          <Moment format="DD.MM.YYYY HH:mma">{updated_at}</Moment>
        </div>
      </div>
      <div className="single_item_rosi items_positioning">
        <div className="single_item_key_style">ROSI</div>
        <div
          className="single_item_value_style"
          style={{ color: rosi_score < 1 && '#eb462b' }}>
          {rosi_score}
        </div>
      </div>
      <img alt='arrow' className="single_item_arrow" src={arrow} />
    </div>
  );
};

const ImageSize = ({ logo_url }) => {

  const [width, setWidth] = useState('42px')
  const imageLoad = (event) => {
    let { naturalWidth, naturalHeight } = event.target
    if (naturalWidth >= naturalHeight) { setWidth('70px') }
  }
  return (<img
    alt='logo'
    src={logo_url}
    onLoad={imageLoad}
    style={{ width }} />)
}
