import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '../../redux/store';
import { LOGOUT } from '../../redux/actions/types';
import { authRequest } from './interceptors';
import log from "loglevel";
import tokenStorage from "../tokenStorage";

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}` || 'http://api.kindred.local',
});

const refreshAuthLogic = (failedRequest) => {
  if (!tokenStorage.getItem('authtoken')) return;
  return http
    .post('/refresh')
    .then((tokenRefreshResponse) => {

      let token = tokenRefreshResponse?.data?.data?.token;

      if (token) {
        tokenStorage.setItem('authtoken', tokenRefreshResponse?.data?.data?.token);
        return Promise.resolve();
      }

      throw new Error("Refresh failed!!");

    })
    .catch((err) => {
      tokenStorage.removeItem('authtoken');
      store.dispatch({ type: LOGOUT });
      log.error(err);
    });
};

createAuthRefreshInterceptor(http, refreshAuthLogic);

http.interceptors.request.use(authRequest);
