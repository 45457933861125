import React from 'react';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import kindredLogo from 'assets/images/kindredLogo.png';
import {NavLink, Redirect, withRouter} from 'react-router-dom';

import './Header.less';

const Header = ({ sponsorship_evaluation, sponsorship_selection, admin, methodology, history }) => {

  const logoutAction = () => {
    return history.push('/user-profile');
  };

  return (
    <div className="headerr">
      <div className="header-elements">
        <img alt='logo' src={kindredLogo} className="logo" />
        <NavLink
          className="nav-tab-item"
          activeClassName="nav-tab-active"
          to="/kindred-properties">
          Kindred Properties
        </NavLink>
        {sponsorship_selection && <NavLink
          className="nav-tab-item"
          activeClassName="nav-tab-active"
          to="/sponsorship-selection">
          Sponsorship Selection
        </NavLink>}
        {sponsorship_evaluation && <NavLink
          className="nav-tab-item"
          activeClassName="nav-tab-active"
          to="/sponsorship-evaluation">
          Sponsorship Evaluation
        </NavLink>}
        {methodology && <NavLink
          className="nav-tab-item"
          activeClassName="nav-tab-active"
          to="/methodology">
          Methodology
        </NavLink>}
        {admin && <NavLink
          className="nav-tab-item"
          activeClassName="nav-tab-active"
          to="/admin">
          Admin
        </NavLink>}
      </div>
      <div className="logout">
        <div onClick={logoutAction} className="logout-button">
          <Icon type="user" style={{ fontSize: '20px' }} />
          <span style={{ marginLeft: '15px', fontSize: '20px' }}>Profile</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sponsorship_evaluation: state.auth?.headerTabs?.sponsorship_evaluation,
  sponsorship_selection: state.auth?.headerTabs?.sponsorship_selection,
  admin: state.auth?.headerTabs?.admin,
  methodology: state.auth?.headerTabs?.methodology
});

export default connect(mapStateToProps)(withRouter(Header));
