import { PROPERTY, UPDATE_LOADING_PROPERTY, UPDATE_SEASON } from '../actions/types'

const initialState = {
    property: {},
    loading: true,
    season: {},
    strategicPerformance: {},
    seasonsList: []
}


export default function (state = initialState, action) {

    const { type, payload, id } = action
    switch (type) {
        case PROPERTY:
            return {
                loading: false,
                property: payload,
                season: payload.seasons[0],
                strategicPerformance: payload.strategic_performance,
                seasonsList: payload?.seasons?.map(el => `${el.start_year} - ${el.end_year}`)
            }

        case UPDATE_LOADING_PROPERTY:
            return {
                property: {},
                loading: true,
                season: {},
                strategicPerformance: {}
            }

        case UPDATE_SEASON:
            return {
                ...state,
                season: state.property.seasons[id]
            }

        default:
            return state
    }

}