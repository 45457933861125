import React from 'react'
import { Bar } from 'react-chartjs-2'
import formatNumber from 'utils/formatNumber'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = (props) => {

    const { dataVal } = props
    const { align, preffix, suffix, barPercentage, labels, dataChart, backgroundColor, maxVal, width } = dataVal

    const data = {
        labels: labels,
        datasets: [{
            data: dataChart,
            backgroundColor: backgroundColor
        }]
    }

    const options = {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        scaleShowLabels: false,
        centerText: {
            display: true,
            text: 'dfs'
        }, legend: {
            display: false
        },
        scales: {
            yAxes: [{
                gridLines: {
                    display: false,
                },

                ticks: {
                    max: maxVal,
                    display: false,
                    beginAtZero: true,
                    fontFamily: 'Factoria'
                }
            }],
            xAxes: [{
                barPercentage: barPercentage,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontFamily: 'Factoria',
                    fontSize: 10
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: align,
                color: '#000000',
                formatter: function (value) {
                    return `${preffix}${formatNumber(value)}${suffix}`;
                },
                font: {
                    color: '#000000',
                    family: 'Factoria',
                    size: 14
                }
            }
        }
    }

    return (
        <div>
            <Bar
                data={data}
                width={width}
                height={340}
                options={options}
            />
        </div>
    )
}

export default PieChart
