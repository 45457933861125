import React, { useEffect } from 'react';
import './KindredProperty.less';
import { Route, Switch, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs, Icon, Select } from 'antd';
import {
  getProperty,
  updateLoadingProperty,
  updateSeason
} from '../../redux/actions/property';
import Moment from 'react-moment';
import formatNumber from '../../utils/formatNumber';
import TangiblePerformance from './TangiblePerformance/TangiblePerformance';
import StrategicPerformance from './StrategicPerformance/StrategicPerformance';
import AudienceMedia from './AudienceMedia/AudienceMedia';
import PropertyPerformance from './PropertyPerformance/PropertyPerformance';
import DoughnutChart from '../ChartComponents/DoughnutChart';
import HalfDoughnutChart from '../ChartComponents/HalfDoughnutChart';
import { Element, scroller } from 'react-scroll';
import { http } from 'services/http';

import { useHistory } from 'react-router-dom';
import info from 'assets/icons/info.svg'
import log from "loglevel";
const { TabPane } = Tabs;
const { Option } = Select;

const KondredProperty = ({
  loading,
  property,
  season,
  getProperty,
  updateSeason,
  updateLoadingProperty,
  seasonsList,
  methodologyRosi,
  rights_and_activations
}) => {
  let history = useHistory();
  let { property_num } = useParams();

  const {
    region,
    market,
    type,
    sport,
    sponsorship_property,
    updated_at,
    logo_url,
    rosi_averages,
    strategic_performance
  } = property;
  const {
    rosi_score,
    rights_and_activation_fee,
    sales_impact_value,
    total_sponsorship_value,
    total_tangible_value,
    tangible_performance
  } = season;

  useEffect(() => {
    getProperty(property_num);
    return () => {
      updateLoadingProperty();
    };
  }, []);

  const callback = (route) => {
    // history.push(`/kindred-property#${route}`)
    scroller.scrollTo(route, {
      duration: 500,
      delay: 10,
      smooth: true,
      offset: -10,
    });
  };
  function handleChange(value) {
    updateSeason(seasonsList.indexOf(value))
  }

  const toggleFavorite = async (e) => {
    try {
      if (property.is_favorite) {
        // remove favorite\
        await http.delete(`/favorites/${property_num}`);
      } else {
        // add favorite
        await http.post(`/favorites/${property_num}`);
      }
      getProperty(property_num);
    } catch (err) {
      console.log(err);
      log.error(err)
    }
  };
  const getReportUri = (id) => {
    let params = `start_year=${season.start_year}&end_year=${season.end_year}&token=${localStorage.getItem('authtoken')}`;
    return `${process.env.REACT_APP_API_URL}/properties/${id}/report-download?${params}`;
  };

  return (
    !loading && (
      <>

        <div className='back_to_properties' onClick={() => { history.goBack() }}>
          <Icon type="arrow-left" />
          <div>Back</div>
        </div>
        <div className="flex_container kindred_property">

          <div
            className="flex_item"
            style={{ width: '23%', marginBottom: 'none' }}>
            <div className="sider" style={{ marginTop: '30px' }}>
              <div
                className="sider_element shadow"
                style={{ paddingBottom: '10px' }}>
                <div style={{ textAlign: 'left' }}>
                  <img src={logo_url} style={{ height: '70px' }} />
                </div>
                <div className="club_name">
                  {sponsorship_property && sponsorship_property}
                </div>
                <div>
                  <Icon
                    onClick={toggleFavorite}
                    type="star"
                    theme={property.is_favorite ? 'filled' : 'outlined'}
                    style={{ color: '#e72b78' }}
                  />
                  {/* <Icon type="download" style={{ 'margin-left': '10px' }} /> */}
                </div>
              </div>
              <div
                className="sider_element shadow"
                style={{ flexFlow: 'row' }}>
                <div className='property_info_key'>
                  <div>Region</div>
                  <div>Market</div>
                  <div>Type</div>
                  <div>Sport</div>
                </div>
                <div className='property_info_value'>
                  <div>{region && region}</div>
                  <div>{market && market}</div>
                  <div>{type && type}</div>
                  <div>{sport && sport}</div>
                </div>
              </div>
              <div className="sider_element shadow">
                <div className="season_select"> Season Selection</div>
                <Select
                  defaultValue={seasonsList[0]}
                  style={{ width: '100%', height: '30px' }}
                  dropdownStyle={{ maxWidth: "12.3%"}}
                  className="selection"
                  onChange={handleChange}>
                  {seasonsList.map(el => <Option key={el} value={el}>Season {el}</Option>)}
                </Select>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: '22px',
                    marginBottom: '5px',
                    color: '#e72b78',
                  }}>
                  Last Update
              </div>
                <div>
                  {' '}
                  {updated_at && (
                    <Moment format="DD/MM/YYYY HH:mma">{updated_at}</Moment>
                  )}
                </div>
              </div>
              <div className="sider_element shadow">
                <a href={getReportUri(property.id)}
                   className="download_report" target="_blank">Download report</a>
              </div>
            </div>
          </div>
          <div className="flex_item" style={{ width: '77%', overflow: 'auto' }}>
            <div className="right" style={{ marginTop: '30px' }}>
              <div className="first_element">
                <div className="score_rights">
                  <div className="score shadow">
                    <div className="rosi_score">ROSI Score</div>
                    {methodologyRosi && <img src={info} className='info_image'
                      onClick={() => { history.push('/methodology?tab=rosi') }} />}
                    {HalfDoughnutChart(rosi_score, 30, rosi_score < 1 ? '#eb462b' : '#2ea836')}
                    <div className="kindred_market_wrapper">
                      <div className="kindred_market_data">
                        <div className="kindred_market_text">Kindred Average</div>
                        <div
                          className="kindred_market_num"
                          style={{ color: rosi_averages?.kindred > 1 ? '#04ad63' : '#eb462b' }}>
                          {rosi_averages?.kindred}
                        </div>
                      </div>
                      <div className="line_separator"></div>
                      <div className="kindred_market_data">
                        <div className="kindred_market_text">Sport average</div>
                        <div
                          className="kindred_market_num"
                          style={{ color: rosi_averages?.market > 1 ? '#04ad63' : '#eb462b' }}>
                          {!!rosi_averages?.market ? rosi_averages?.market : <div className='no_data'>No data available</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  {rights_and_activations && <div className="rights shadow">
                    <div className="rights_activations">Rights & Activations</div>
                    <div className="rights_activations_price">
                      <Icon
                        type="dollar"
                        style={{ fontSize: '37px', color: '#dbdbdb' }}
                      />
                      <div
                        style={{ mrginBottom: '10px', marginLeft: '20px' }}>
                        £{' '}
                        {!!rights_and_activation_fee ?
                          formatNumber(rights_and_activation_fee) : <div className='no_data'>No data available</div>}
                      </div>
                    </div>
                  </div>}
                </div>
                <div className="property shadow">
                  <div>
                    <div className="property_value">Property Value</div>
                    <div className="doughnut_chart_wrapper">
                      <div className="doughnut_chart">
                        {!!total_tangible_value ? 'Property Value Breakdown' : 'No data available'}
                      </div>
                      {!!total_tangible_value && DoughnutChart(total_tangible_value, sales_impact_value)}
                    </div>
                  </div>
                  <div style={{ marginLeft: '160px', marginTop: '72px' }}>
                    <div className="total_value_name">Total Value</div>
                    <div className="total_value_price">
                      £{' '}
                      {!!total_sponsorship_value ?
                        formatNumber(total_sponsorship_value) : <div className='no_data'>No data available</div>}
                    </div>

                    <div className="property_value_name tangible_value_name">
                      <div
                        style={{ backgroundColor: '#00a5de' }}
                        className="property_dot"></div>
                      Tangible Value (key markets)
                  </div>
                    <div className="property_value_price tangible_value_price">
                      £{' '}
                      {!!total_tangible_value ? formatNumber(total_tangible_value) : <div className='no_data'>No data available</div>}
                    </div>

                    {/* <div className='property_value_name intangible_value_name'>
                                    <div style={{ 'background-color': '#e72b78' }} className='property_dot'></div>
                                    Intangible Value</div>
                                <div className='property_value_price intangible_value_price'>£ 361,306</div> */}

                    <div className="property_value_name sales_impact_value_name">
                      <div
                        style={{ backgroundColor: '#992785' }}
                        className="property_dot"></div>
                    Brand Funnel Impact Value
                  </div>
                    <div className="property_value_price sales_attribution_price">
                      £ {!!sales_impact_value ? formatNumber(sales_impact_value) : <div className='no_data'>No data available</div>}
                    </div>
                  </div>
                </div>
              </div>
              {(tangible_performance && strategic_performance) && <div className="nav_bar shadow">
                <div className="navbar-elements">
                  <Tabs
                    defaultActiveKey={'asdf'}
                    onChange={callback}
                    className="tabs tabs_nav">
                    {tangible_performance && <TabPane
                      tab="Tangible Performance"
                      key="tangible"
                      style={{ display: 'none' }}
                    />}
                    {!!strategic_performance && <TabPane
                      tab="Strategic Performance"
                      key="strategic"
                      style={{ display: 'none' }}
                    />}
                    {/* <TabPane tab="Property Performance" key="property" style={{ display: 'none' }} /> */}
                  </Tabs>
                </div>
              </div>}
              {/* <Switch>
                        <Route path='/kindred-property/tangible-performance' component={TangiblePerformanceWrap} />
                        <Route path='/kindred-property/strategic-performance' component={StrategicPerformance} />
                        <Route path='/kindred-property/property-performance' component={PropertyPerformance} />
                    </Switch> */}
              {tangible_performance && <Element name="tangible">
                <TangiblePerformanceWrap season={season} />
              </Element>}
              <Element name="strategic">
                <StrategicPerformance />
              </Element>
              {/* <Element name="property">
                        <PropertyPerformance />
                    </Element> */}
            </div>
          </div>
        </div>
      </>
    )
  );
};
const mapStateToProps = (state) => {
  const { loading } = state.property;

  return {
    property: state.property.property,
    loading,
    season: state.property.season,
    seasonsList: state.property.seasonsList,
    methodologyRosi: state.auth?.methodology?.rosi_model_methodology,
    rights_and_activations: state.auth?.propertyPermisions?.rights_and_activations
  };
};
export default connect(mapStateToProps, { getProperty, updateLoadingProperty, updateSeason })(
  KondredProperty,
);

const TangiblePerformanceWrap = ({ season }) => {
  const { audience_media_value, tangible_performance } = season;
  return (
    <>
      {tangible_performance && <TangiblePerformance tangible_performance={tangible_performance} />}
      {audience_media_value && <AudienceMedia audience_media_value={audience_media_value} />}
    </>
  );
};
