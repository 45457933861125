import React from 'react';
import { Tabs, Icon } from 'antd';
import { connect } from "react-redux"
import {
  Route,
  Switch,
  useHistory,
  useParams,
  Redirect,
} from 'react-router-dom';

import ProperyMap from './PropertyMap/PropertyMap';
import PropertyAnalysis from './PropertyAnalysis/PropertyAnalysis';
import AssetAnalysis from './AssetAnalysis/AssetAnalysis';

import './SponsorshipEvaluation.less';

const { TabPane } = Tabs;

const SponsorshipEvaluation = ({ sponsorship_evaluation }) => {
  let history = useHistory();
  let { sub_cat } = useParams();
  const { property_map, property_analysis, asset_analysis } = sponsorship_evaluation

  const callback = (route) => {
    history.push(`/sponsorship-evaluation/${route}`);
  };

  return (
    <div className="sponsorship_evaulation">
      <div className="sponsorship_evaulation_tabs">
        <Tabs defaultActiveKey={sub_cat} onChange={callback}>
          {property_map && <TabPane
            tab="Property Map"
            key="property-map"
            style={{ display: 'none' }}
          />}
          {property_analysis && <TabPane
            tab="Property Analysis"
            key="property-analysis"
            style={{ display: 'none' }}
          />}
          {asset_analysis && <TabPane
            tab="Asset Analysis"
            key="asset-analysis"
            style={{ display: 'none' }}
          />}
        </Tabs>
      </div>
      <Switch>
        {property_map && <Route
          path="/sponsorship-evaluation/property-map"
          component={ProperyMap}
        />}
        {property_analysis && <Route
          path="/sponsorship-evaluation/property-analysis"
          component={PropertyAnalysis}
        />}
        {asset_analysis && <Route
          path="/sponsorship-evaluation/asset-analysis"
          component={AssetAnalysis}
        />}
        <Route
          render={() => <Redirect to={`/sponsorship-evaluation/${property_map && 'property-map' || property_analysis && 'property-analysis' || asset_analysis && 'asset-analysis'}`} />}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sponsorship_evaluation: state.auth?.sponsorship_evaluation
});

export default connect(mapStateToProps)(SponsorshipEvaluation);

export const FilterSingleItem = ({
  name,
  direction,
  click,
  property_analysis,
}) => {
  const filter_property_analysis = {
    fontSize: '16px',
    marginTop: '10px',
  };

  return (
    <div
      className="filter_single_item"
      style={property_analysis && filter_property_analysis}
      onClick={click}>
      <div>{name}</div>
      <Icon type={direction ? 'down' : 'right'} style={{ fontSize: '14px' }} />
    </div>
  );
};
