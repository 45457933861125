import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import styles from './Login.module.css';
import { connect } from 'react-redux';
import kindred_colors from 'assets/images/kindred.png';
import kindred_logo from 'assets/images/kindred-logo.png';
import group from 'assets/images/group.png';
import { login } from '../../redux/actions/auth';

const Login = ({ form, login, isAuthenticated, loading }) => {
  const { getFieldDecorator, validateFields } = form;
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((error, values) => {
      if (!error) {
        login(values);
      }
    });
  };

  //Redirect if logged in
  if (isAuthenticated) return <Redirect to="/kindred-properties" />;

  return (
    <div className={styles.auth}>
      <img alt='kindred' src={kindred_colors} className={styles.kindred_colors} />
      <img alt='kindred' src={kindred_logo} className={styles.kindred_logo} />
      <img alt='kindred' src={group} className={styles.group} />
      <span className={styles.login_name}>Login</span>
      <div style={{ height: '50px' }}></div>
      <div className={styles.login_form}>
        <Form onSubmit={handleSubmit} className={styles.login_antd_form}>
          <Form.Item>
            {getFieldDecorator('email', {
              // rules: [
              //     {
              //         required: true,
              //         message: "Please input your Email!"
              //     },
              //     {
              //         type: "email",
              //         message: "The input is not valid Email!"
              //     }
              // ]
            })(<Input className={styles.login_input} placeholder="Email" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              // rules: [
              //     {
              //         required: true,
              //         message: "Please input your Password!"
              //     },
              //     { min: 6, message: "Password should be at least 6 characters" }
              // ]
            })(
              <Input.Password
                type="password"
                placeholder="Password"
                className={styles.login_input}
                style={{ marginTop: '20px' }}
              />,
            )}
          </Form.Item>
          <div></div>
          <span
            style={{
              alignSelf: 'flex-start',
              textDecoration: 'underline',
              fontFamily: 'Factoria',
              fontSize: '20px',
            }}>
            <Link to="/reset-password-email">Forgot password?</Link>
          </span>
          <div>
            <Button loading={loading} htmlType="submit" className={styles.login_button}>
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { login })(Form.create()(Login));
