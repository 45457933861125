import React from 'react'
import './TangiblePerformance.less'
import shape from 'assets/images/shape.png'
import formatNumber from '../../../utils/formatNumber'

const PercentageBar = (media, percentage, value) => {

    const calculatePercentage = () => {
        return (432 / 100) * percentage + 'px'
    }
    return (
        <div className='percentage_bar' style={{ backgroundColor: media[1] }} >
            {!!value ?
                <div className='percentage_bar_value' style={{ width: calculatePercentage(), backgroundColor: media[0] }}>
                    <div className='percentage_val'>{`${percentage}%`}</div>
                </div> :
                <div className='no_data_available'>No data available</div>}
        </div>
    )
}

const TangiblePerformance = ({ tangible_performance }) => {

    const { online, print, tangible_value, tv, social_media } = tangible_performance;
    const onePercent = tangible_value / 100;

    const tvPercentage = Math.round(tv / onePercent)
    const onlinePercentage = Math.round(online / onePercent)
    const printPercentage = Math.round(print / onePercent)
    const socialPercentage = Math.round(social_media / onePercent)

    const media = {
        tv: ['#00a5de', '#def7ff'],
        print: ['#f39000', '#fff2df'],
        online: ['#e72b78', '#fbdbe8'],
        social: ['#992785', '#f6dcf2']
    }
    return (
        <div className='tangible_performance shadow'>
            <div className='tangible_performance_wrapper'>
                <div className='tangible_performance_name'>Tangible Performance</div>
                <img className='tangible_image' src={shape} />
                <div className='tan_value_name'>Tangible Value</div>
                <div className='tan_value_price'>£{formatNumber(Math.round(tangible_value))}</div>

            </div>
            <div className='media_statistic'>
                <div className='media'>
                    <div>TV</div>
                    <div>Print</div>
                    <div>Online</div>
                    <div>Social</div>
                </div>
                <div className='statistic'>
                    {PercentageBar(media.tv, tvPercentage, tv)}
                    {PercentageBar(media.print, printPercentage, print)}
                    {PercentageBar(media.online, onlinePercentage, online)}
                    {PercentageBar(media.social, socialPercentage, social_media)}
                </div>
                <div className='price'>
                    {tv ? <div>£{formatNumber(Math.round(tv))}</div> : <div></div>}
                    {print ? <div>£{formatNumber(Math.round(print))}</div> : <div></div>}
                    {online ? <div>£{formatNumber(Math.round(online))}</div> : <div></div>}
                    {social_media ? <div>£{formatNumber(Math.round(social_media))}</div> : <div></div>}
                </div>
            </div>
        </div>
    )
}

export default TangiblePerformance