import React, { useState, useEffect } from 'react';
import { Icon, Input, Form, Tabs } from 'antd';
import { http } from 'services/http';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { FilterSingleItem } from '../SponsorshipEvaluation';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import magnify from 'assets/icons/Magnify.svg'
import BubleChartPropertyAnalysis from '../../ChartComponents/BubleChartPropertyAnalysis';
import { propertyMapSearchData } from '../../../redux/actions/propertyMap';

import './PropertyAnalysis.less';

const { TabPane } = Tabs;
const { Search } = Input;
let currentColor = 0

const PropertyAnalysis = ({
  searchData,
  loading,
  propertyMapSearchData,
  location,
  form
}) => {
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const { getFieldDecorator, resetFields } = form;

  const filterData = [
    { name: 'Region', type: 'regions' },
    { name: 'Market', type: 'markets' },
    { name: 'Brand/Operator', type: 'brands' },
    { name: 'Sport', type: 'sports' },
    { name: 'Type', type: 'types' },
  ];

  const [arrowDirection, setArrowDirection] = useState({
    regions: true,
    markets: false,
    brands: false,
    sports: false,
    type: false,
    properties: true,
  });

  const [searchQuery, setSearchQuery] = useState({
    brands: [],
    markets: [],
    properties: [],
    regions: [],
    sports: [],
    types: [],
  });

  const [searchInput, setSearchInput] = useState({
    brands: [],
    markets: [],
    properties: [],
    regions: [],
    sports: [],
    types: [],
  });
  const [marketsSearchInput, setMarketsSearchInput] = useState([])
  const [propertiesData, setPropertiesData] = useState([]);
  const [queryNum, setQueryNum] = useState(1)
  const colors = ['#992785', '#e72b78', '#2ea836', '#00a5de', '#f39000']

  useEffect(() => {
    const { markets, ...dataForSearch } = searchData
    setSearchInput({ ...searchInput, ...dataForSearch })

  }, [searchData])

  useEffect(() => {
    setMarketsSearchInput(getAllMarkets())
    setSearchInput({ ...searchInput, markets: getAllMarkets() })
  }, [searchQuery.regions])

  async function fetchPropertyData(query) {
    // const query = qs.parse(location.search.substr(1));
    try {
      const res = await http.post('/properties/filter/property-analysis', query);
      let resData = res?.data?.data?.properties;
      if (resData) {
        resData.forEach(el => {
          currentColor++
          if (currentColor === 5) { currentColor = 0 }
          el.color = colors[currentColor]
        })
        setPropertiesData([...propertiesData, ...resData].filter((x, i, a) =>
          a.findIndex(e => e.id == x.id) == i
        ));
      }
    } catch {
      // setPropertiesData([]);
    }
  }

  async function fetchFilteredData(query) {
    // const query = qs.parse(location.search.substr(1));
    try {
      const res = await http.post('/properties/filter/property-analysis', query);
      let filtered = res?.data?.data?.filteredPoint;
      let filters = res?.data?.data?.filters;
      const filteredData = { ...filtered, ...filters, id: uuidv4(), sponsorship_property: `Query ${queryNum}` }
      setQueryNum(queryNum + 1)
      currentColor++
      if (currentColor === 5) { currentColor = 0 }
      filteredData.color = colors[currentColor]
      if (filtered && filters) {
        setPropertiesData([...propertiesData, filteredData]);
      }
    } catch {
      // setPropertiesData([]);
    }
  }

  useEffect(() => {
    // const query = qs.parse(location.search.substr(1));
    propertyMapSearchData();
    // setSearchQuery({ ...searchQuery, ...query });
    // if (!!Object.keys(query).length) fetchData(query);
    // setSearchQuery({ ...qs.parse(location.search.substr(1)) })
  }, []);

  // useEffect(() => {
  // }, [searchQuery]);

  const setArrow = (propType) => {
    getAllMarkets()
    setArrowDirection({
      ...arrowDirection,
      [propType]: !arrowDirection[propType],
    });
  };

  const searchDataQuery = (str, propType) => {
    let allMarkets = getAllMarkets()
    let res;
    if (str.length > 0) {
      if (propType == 'markets') {
        res = allMarkets.filter((item) =>
          item.toLowerCase().includes(str.toLowerCase()),
        );
      } else {
        res = searchData[propType].filter((item) =>
          item.toLowerCase().includes(str.toLowerCase()),
        );
      }
      setSearchInput({ ...searchInput, [propType]: res });
    } else {
      if (propType == 'markets') {
        setSearchInput({ ...searchInput, markets: getAllMarkets() })
      } else {
        setSearchInput({ ...searchInput, [propType]: searchData[propType] });
      }
    }
  };

  const setQueryParam = (item, propType) => {
    const newQuery = {
      ...searchQuery,
      [propType]: [...new Set([...searchQuery[propType], item])],
    };
    setSearchQuery(newQuery);
    // history.push(`${url}?${qs.stringify(newQuery)}`);
  };

  const removeQueryParam = (item, propType) => {
    const selectedQuery = new Set(searchQuery[propType]);
    selectedQuery.delete(item);
    const newQuery = { ...searchQuery, [propType]: [...selectedQuery] };
    setSearchQuery(newQuery);
    // history.push(`${url}?${qs.stringify(newQuery)}`);
  };

  const addToChart = () => {
    const { properties, ...rest } = searchQuery
    fetchFilteredData(rest);
  };

  const addPropertyToChart = () => {
    let query = { properties: searchQuery.properties }
    fetchPropertyData(query);
  }

  const resetChart = () => {
    setSearchQuery({
      brands: [],
      markets: [],
      properties: [],
      regions: [],
      sports: [],
      types: [],
    });
    setSearchInput({
      brands: [],
      markets: [],
      properties: [],
      regions: [],
      sports: [],
      types: [],
    });
    setPropertiesData([]);
    resetFields()
    // history.push(`${url}`);
  };

  const removePropertyItem = (id) => {
    setPropertiesData(propertiesData.filter((el) => el.id !== id));
  };

  const dataChartFormaterRosi = (data) => {
    let res = data.map((el) => {
      return {
        label: el.sponsorship_property,
        data: [
          {
            x: el.rosi_score * 1000000,
            y: el.total_tangible_value,
            r: el.rosi_score + 30,
            val: el.rosi_score,
          },
        ],
        backgroundColor: el.color,
      };
    });
    return res;
  };
  const dataChartFormater = (data) => {
    let res = data.map((el) => {
      return {
        label: el.sponsorship_property,
        data: [
          {
            x: el.total_tangible_value,
            y: el.rights_and_activation_fee,
            r: el.rosi_score + 30,
            val: el.rosi_score,
          },
        ],
        backgroundColor: el.color,
      };
    });
    return res;
  };
  const getAllMarkets = () => {
    const regions = searchQuery.regions.map((el) => {
      return searchData.markets[el]
    })
    return regions.flat()
  }

  const SingleSearchField = (name, type) => {
    return (
      <div key={name}>
        <FilterSingleItem
          name={name}
          direction={arrowDirection[type]}
          click={() => setArrow(type)}
        />
        {arrowDirection[type] && (
          <div>
            {searchQuery[type] && !!searchQuery[type].length && (
              <div className="sponsorship_evaulation_filter_results">
                {searchQuery[type].map((item) => (
                  <div
                    key={item}
                    className="filter_item filter_item_selected"
                    onClick={() => removeQueryParam(item, type)}>
                    {item}
                    <Icon
                      type="close"
                      style={{ fontSize: '11px', marginLeft: '12px' }}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className="filter_search_input_field">
              <Form.Item>
                {getFieldDecorator(type)(
                  <Search
                    placeholder="Start typing"
                    onChange={(e) => searchDataQuery(e.target.value, type)}
                    disabled={
                      type == 'markets' && !searchQuery.regions.length
                        ? true
                        : false
                    }
                  />
                )}
              </Form.Item>
            </div>
            <div className="sponsorship_evaulation_filter_results search_results">
              {type != 'markets' ? (searchInput[type] &&
                searchInput[type].map((item) => {
                  return (
                    <div
                      key={item}
                      className="filter_item"
                      onClick={() => setQueryParam(item, [type])}>
                      {item}
                    </div>
                  );
                })) :
                (searchInput.markets.map((item) => {
                  return (
                    <div
                      key={item}
                      className="filter_item"
                      onClick={() => setQueryParam(item, [type])}>
                      {item}
                    </div>
                  );
                }))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const PropertySearchField = (type) => {
    return (
      <div>
        {searchQuery[type] && !!searchQuery[type].length && (
          <div className="sponsorship_evaulation_filter_results">
            {searchQuery[type].map((item) => (
              <div
                key={item}
                className="filter_item filter_item_selected"
                onClick={() => removeQueryParam(item, type)}>
                {item}
                <Icon
                  type="close"
                  style={{ fontSize: '11px', marginLeft: '12px' }}
                />
              </div>
            ))}
          </div>
        )}
        <div className="filter_search_input_field">
          <Form.Item>
            {getFieldDecorator(type)(
              <Search
                placeholder="Start typing"
                onChange={(e) => searchDataQuery(e.target.value, type)}
                disabled={
                  type == 'markets' &&
                    !searchData.regions.includes(searchQuery.regions[0])
                    ? true
                    : false
                }
              />
            )}
          </Form.Item>
        </div>
        <div className="sponsorship_evaulation_filter_results search_results">
          {searchInput[type] &&
            searchInput[type].map((item) => {
              return (
                <div
                  key={item}
                  className="filter_item"
                  onClick={() => setQueryParam(item, [type])}>
                  {item}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const callback = (tab) => {
  }

  return (
    !loading && (
      <div className="sponsorship_evaulation_layout">
        <div style={{ width: '20%', display: 'flex', flexFlow: 'column', height: 'fit-content' }}>
          <Form>
            <div
              className="sponsorship_evaulation_filter"
              style={{ width: 'auto' }}>
              <div style={{ margin: '40px' }}>
                <div className="chart_set_up">Chart Set Up</div>
                <div className="reset_chart" onClick={resetChart}>
                  {' '}
                  <Icon type="reload" style={{ marginRight: '4px' }} />
                Reset Chart
              </div>
                <div className="search_property">Search Property</div>
                <div className="search_property_description">
                  Search a property by its name to add <br /> it to your chart:
              </div>
                {/* ======== Property Filter ======= */}
                {PropertySearchField('properties')}

                <div className="reset_all_filters" onClick={addPropertyToChart}>
                  Add to chart
              </div>
              </div>
            </div>

            <div
              className="sponsorship_evaulation_filter"
              style={{ width: 'auto', height: '100%' }}>
              <div style={{ margin: '40px' }}>
                <div className="filters_select_property">
                  Filters & Select Property
              </div>
                <div className="search_property_description">
                  Search the filters below to find the property <br />
                you wish to add to the chart:
              </div>
                {/* ======== Filters ========= */}
                {filterData.map(({ name, type }) => {
                  return SingleSearchField(name, type);
                })}

                <div className="reset_all_filters" onClick={addToChart}>
                  Add to chart
              </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="sponsorship_evaulation_result">
          <div className="propery_analysis_">
            <div className="property_analysis_name"> Property Analysis</div>
            <div className="analysis_box_wrapper">
              {propertiesData.map((el) => {
                const {
                  id,
                  rosi_score,
                  sponsorship_property,
                  color,
                  ...rest
                } = el
                return (
                  <PropertyAnalysisBox
                    key={id}
                    id={id}
                    color={color}
                    name={sponsorship_property}
                    rosi={rosi_score}
                    click={() => removePropertyItem(id)}
                    tooltip={rest}
                  />
                );
              })}
            </div>
            <div className='audience_media_tabs property_analysis_tabs' style={{ position: 'relative' }}>
              <Tabs defaultActiveKey={'asdf'} onChange={callback} >
                <TabPane tab="Rights & Activations / Total Value" key="rights_activations" >
                  <div className="property_analysis_chart">
                    <div className="y_axes_title" style={{ top: '196px', left: '-10px' }}>Rights & Activations</div>
                    {!!propertiesData.length ? (
                      <BubleChartPropertyAnalysis
                        data={dataChartFormater(propertiesData)}
                      />
                    ) : <BubleChartPropertyAnalysis data={[]} />}
                    <div className="x_axes_title">Total Value</div>
                  </div>
                </TabPane>
                <TabPane tab="Total Value / ROSI Score" key="rosi_score" >
                  <div className="property_analysis_chart">
                    <div className="y_axes_title">Total Value </div>
                    {!!propertiesData.length ? (
                      <BubleChartPropertyAnalysis
                        data={dataChartFormaterRosi(propertiesData)}
                      />
                    ) : <BubleChartPropertyAnalysis data={[]} />}
                    <div className="x_axes_title">Rosi Score</div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const { loading, propertyMapData } = state.propertyMap;

  return {
    searchData: propertyMapData,
    loading,
  };
};

export default connect(mapStateToProps, { propertyMapSearchData })(
  Form.create()(PropertyAnalysis)
);

const PropertyAnalysisBox = ({ color, name, rosi, click, tooltip }) => {
  if (name.length > 15) name = name.substr(0, 15) + '...';

  const { market, sport, region, brand, type, logo_url } = tooltip;
  const { markets, sports, regions, brands, types } = tooltip;

  const [showTooltip, setShowTooltip] = useState(false)
  const setToolTip = () => { setShowTooltip(true) }
  const setTooltipLeave = () => { setShowTooltip(false) }
  return (
    <div onMouseOver={setToolTip} onMouseLeave={setTooltipLeave} className="property_analysis_box_wrapper">
      <div className="property_analysis_box">
        {logo_url ? <img src={logo_url} style={{ height: '25px' }} /> : <img src={magnify} />}
        <div style={{ marginLeft: '8px', marginRight: '20px' }}>
          <span className="property_analysis_box_name">{name}</span>
          <br />
          <span style={{ color }}>ROSI </span>{' '}
          <span style={{ marginLeft: '10px' }}>{rosi}</span>
        </div>
      </div>
      <div
        className="property_analysis_flag"
        style={{ backgroundColor: color }}></div>
      <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '4px',
          cursor: 'pointer',
        }}
        onClick={click}>
        X
      </div>
      {showTooltip && <div className='tooltip_hover' style={{ borderBottom: `solid 4px ${color}` }}>
        <div className='hover_element'>
          <div className='hover_element_key'>Region:</div>
          <div className='hover_element_value'>
            {!!regions ? regions.map(el => <span key={el} className='comma'>{el}</span>) : region}
          </div>
        </div>
        <div className='hover_element'>
          <div className='hover_element_key'>Market:</div>
          <div className='hover_element_value'>{!!markets ? markets.map(el => <span key={el} className='comma'>{el}</span>) : market}</div>
        </div>
        <div className='hover_element'>
          <div className='hover_element_key'>Brand/Operator:</div>
          <div className='hover_element_value'>{!!brands ? brands.map(el => <span key={el} className='comma'>{el}</span>) : brand}</div>
        </div>
        <div className='hover_element'>
          <div className='hover_element_key'>Sport:</div>
          <div className='hover_element_value'>{!!sports ? sports.map(el => <span key={el} className='comma'>{el}</span>) : sport}</div>
        </div>
        <div className='hover_element'>
          <div className='hover_element_key'>Type:</div>
          <div className='hover_element_value'>{!!types ? types.map(el => <span key={el} className='comma'>{el}</span>) : type}</div>
        </div>
      </div>}
    </div>
  );
};
