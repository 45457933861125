import { PROPERTY_MAP } from '../actions/types'

const initialState = {
    propertyMapData: {},
    loading: true
}



export default function (state = initialState, action) {

    const { type, payload } = action
    switch (type) {
        case PROPERTY_MAP:
            return {
                loading: false,
                propertyMapData: payload
            }
        default:
            return state
    }

}