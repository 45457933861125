import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Body from './Body/Body';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Body>{children}</Body>
      <Footer />
    </>
  );
};

export default Layout;
