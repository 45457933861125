import React from 'react'

const chartDataFormatter = (columnTitles, columnValues, base_met, name, value, maxVal) => {
    const showDisclaimer = (name) => {
        return (<div style={{ textAlign: 'center' }}>{name}<sup style={{ color: 'red' }}>*</sup></div>)
    }

    const nameCentered = (name) => {
        return (<div style={{ textAlign: 'center' }}>{name}</div>)
    }

    return {
        align: 'end',
        suffix: '%',
        barPercentage: 0.8,
        labels: columnTitles,
        dataChart: columnValues,
        backgroundColor: ['rgb(0,165,222)', '#dbdbdb'],
        maxVal,
        width: 200,
        name: base_met ? showDisclaimer(name) : nameCentered(name),
        num: value,
        // backCol: '#007808'
    }
}

export default chartDataFormatter;