import React from 'react'
import './FooterPage.less'

const FooterPage = () => {
    return (
        <div className='footer_page'>
            <div className='legal_information'>Legal information</div>

            <div className='footer_wrap'>
                <div className='footer_element'>
                    <div className='footer_element_name'>Terms and conditions of use</div>
                    <p className='footer_element_text'>
                        Please note that platform and data is subject to copyright agreements. All rights are reserved by Nielsen Sports UK & Ireland Limited. In particular, copyright is reserved for the investigation design together with expert evaluations and assessment of the results, which are the intellectual property of Nielsen Sports UK & Ireland Limited. If it is intended that the analysis and reports from this platform in full or excerpt will be published, prior written consent is required from the proprietors, Nielsen Sports UK & Ireland Limited.
                        <br />
                        <br />
                        Whilst proper due care and diligence has been taken in the preparation of this platform, Nielsen Sports UK & Ireland Limited cannot guarantee the accuracy of the information contained and does not accept any liability for any loss or damage caused as a result of using information or recommendations contained within this document.
                    </p>
                </div>

                <div className='footer_element'>
                    <div className='footer_element_name'>Contact Details</div>
                    <p className='footer_element_text'>
                        <div style={{ color: '#992785' }}>Mateusz Samolyk</div>
                        mateusz.samolyk@kindredgroup.com
                        <br />
                        <br />
                        <div style={{ color: '#992785' }}>Christian Fizia</div>
                        christian.fizia@kindredgroup.com
                    </p>
                </div>

                <div className='footer_element'>
                    <div className='footer_element_name'>Copyright</div>
                    <p className='footer_element_text'>
                        The processes, methodologies, strategies, analysis techniques and materials which are detailed in this platform are confidential and remain the sole and exclusive property of Nielsen Sports UK & Ireland Limited.
                        <br />
                        <br />
                        They are being disclosed in confidence and may not be reproduced, distributed, publicly displayed, adapted or used in any manner, by any other person, or any other entity without the express written consent of Nielsen Sports UK & Ireland Limited.
                        <br />
                        <br />
                        Any unauthorized reproduction, distribution, display, adaptation, or other use of such concepts will constitute breach of protected proprietary rights which are held by Nielsen Sports UK & Ireland Limited, including but not limited to trade secrets, copyright rights, thereby exposing the breaching person or entity to legal remedies.
                        <br />
                        <br />
                        Nielsen Sports UK & Ireland Limited is uploading data to this platform with the understanding that it will be compensated for any use of processes, methodologies, strategies, analysis techniques contained herein. By having viewed this information you have agreed to provide adequate compensation to Nielsen Sports UK & Ireland Limited if you distribute, display, adapt or otherwise use the disclosed processes, methodologies, strategies or analysis.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default FooterPage