import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './ChartComponents.less'

const HalfDoughnutChart = (rosiScore, maxRosiScore, color) => {

    const dataChart = {
        labels: ["Red"],
        datasets: [{
            data: [rosiScore, (maxRosiScore - rosiScore)],
            backgroundColor: [
                color
            ]
        }]
    }

    const HalfDoughnutChart = () => {
        return <Doughnut
            data={dataChart}
            width={230}
            height={120}
            options={{
                rotation: 1 * Math.PI,
                circumference: 1 * Math.PI,
                maintainAspectRatio: false,
                cutoutPercentage: 75,
                scaleShowLabels: false,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        display: false
                    }
                }
            }}
        />
    }

    return (
        <div className='half_doughnut_chart_wrapper'>
            {HalfDoughnutChart()}
            <div className='property_score'>
                <div className='property_score_val'>{!!rosiScore ? <div style={{ color: color }}>{rosiScore}</div> : <div className='no_data' style={{ display: 'block' }}>No data available</div>}</div>
                <div className='property_score_text'>Property Score</div>
            </div>
        </div>
    )
}

export default HalfDoughnutChart