import { http } from 'services/http';
import { PROPERTIES } from './types';

export const getProperties = (query = {}) => async (dispatch) => {
  const params = {
    page: 1,
    limit: 100,
    ...query,
  };
  try {
    const res = await http.get('/properties', { params });

    dispatch({
      type: PROPERTIES,
      payload: res.data.data,
    });
  } catch {}
};
